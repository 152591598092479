import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import '../styles/ForwardingAddressComponent.css'

const ForwardingAddressComponent = ({ forwardingAddress, handleAddressCopy }) => {

    const addressRef = useRef();

    return (
        <div className="forwarding-address">
            <div className="address" onClick={handleAddressCopy}>
                {forwardingAddress &&<><FontAwesomeIcon className="icon inbox" icon={faInbox} />{forwardingAddress}</>}
            </div>
            <button className='copy' onClick={handleAddressCopy}>
                <FontAwesomeIcon className="icon copy" icon={faCopy} />
            </button>
        </div>
    );
};

export default ForwardingAddressComponent;