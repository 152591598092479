import React, { useState, useEffect, useContext } from 'react';
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';
import { ProfileContext } from '../contexts/ProfileContext';
import ForwardingAddressComponent from '../components/ForwardingAddressComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

const ForwardAddress = () => {
  const { showToast, showPrompt } = useGlobalPrompts();
  const { userProfile, refreshProfile } = useContext(ProfileContext);
  const [advance, setAdvance] = useState(false);
  const [forwardingAddress, setForwardingAddress] = useState(null);
  const [forwardingAddressActive, setForwardingAddressActive] = useState(null);
  const [checkIsActive, setCheckIsActive] = useState(false);

  const handleHelp = () => {
    console.log('handle help')
    showPrompt({
      icon: <FontAwesomeIcon className='prompt-icon' icon={faQuestionCircle} />,
      title: 'Get Started',
      description:
        <>
          <p>Just one email gets you started.</p>
          <p>Find a long message from teachers or coaches with important details.</p>
          <p>Forward it to your personal address, and we'll organize everything—never miss a game or event!</p>
        </>,
      confirmMessage: 'Okay'
    });
  }

  const handleAddressCopy = () => {
    navigator.clipboard.writeText(forwardingAddress).then(() => {
      setCheckIsActive(true);
      showToast({
        icon: <FontAwesomeIcon className='icon' icon={faCopy} />,
        title: 'Copied to Clipboard',
        description: forwardingAddress
      });
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  useEffect(() => {
    if (!userProfile) {
      return;
    }

    if (userProfile.forwardingAddress && userProfile.forwardingAddress.id) {

      setForwardingAddress(userProfile.forwardingAddress.address);
      setForwardingAddressActive(userProfile.forwardingAddress.active);

      if (userProfile.forwardingAddress.active) {
        setAdvance(true);
        setCheckIsActive(false); // stop checking
      }
    }
  }, [userProfile])

  useEffect(() => {
    if (checkIsActive) {
      const intervalId = setInterval(() => {
        refreshProfile();
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [checkIsActive]);

  useEffect(() => {
    if (forwardingAddressActive) {
      setCheckIsActive(false);
      setAdvance(true);
      showToast({
        icon: <FontAwesomeIcon className='icon' icon={faCircleCheck} />,
        title: 'Forwarding Confirmed',
        description: "Your address received an email!"
      });
    }
  }, [forwardingAddressActive])

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Your Personal <br />Forwarding Address</h2></div>
      <div className="onboarding-main">
        <h3>Get started by sending an<br /> email to your forwarding address</h3>
        <h3 onClick={handleHelp}>Include school or sports events you <br />want automatically organized <FontAwesomeIcon className='icon' icon={faQuestionCircle} /></h3>
        <ForwardingAddressComponent
          forwardingAddress={forwardingAddress}
          handleAddressCopy={handleAddressCopy}
        />
        <div className='note'>{checkIsActive && <span>Checking for incoming emails...</span>}</div>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/join/success' }}>Continue{checkIsActive && <FontAwesomeIcon className='icon spinner' icon={faSpinner} />}</button>
        {!advance &&
          <button onClick={() => window.history.back()} className="cancel">Go Back</button>
        }
      </div>
    </div>
  );
};

export default ForwardAddress;
