import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InstallPrompt from '../components/InstallPrompt'

const Install = () => {
  const [showInstall, setShowInstall] = useState(false);  // Add state

  const handleInstall = () => {
    setShowInstall(true);  // Show the prompt when button is clicked
  }

  return (
    <div className='home'>
      {showInstall &&
        <InstallPrompt
          icon={<img src='/logo512.png' width={60} height={60} />}
          confirmMessage='Okay'
          cancelMessage='FAQs'
          onConfirm={() => {  }}
          onCancel={() => { document.getElementById('faqs').scrollIntoView({ behavior: 'smooth' }); }}
          onClose={() => { setShowInstall(false) }}
        />}
      <div className='join'>
        <div className='beta'>beta</div>
        <h1>Simple <br />Schedule</h1>
        <button onClick={handleInstall}>Install</button>
      </div>
      <h3 id="faqs">FAQs</h3>
      <div className="faqs">
        <div className="faq">
          <p className="question"><FontAwesomeIcon className="icon" icon={faPlus} />Who is Simple Schedule for?</p>
          <p className="answer">Busy parents and caregivers who need a smarter way to manage their kids' activities—without manually tracking every event.</p>
        </div>
        <div className="faq">
          <p className="question"><FontAwesomeIcon className="icon" icon={faPlus} />How does it work?</p>
          <p className="answer">The app scans messages for key details like deadlines, sports, homework, and upcoming events. It then organizes them into a simple, easy-to-follow schedule.</p>
        </div>
        <div className="faq">
          <p className="question"><FontAwesomeIcon className="icon" icon={faPlus} />Does this app read all my emails?</p>
          <p className="answer">No. The app only scans messages for event-related details and ignores everything else. You have full control over which emails are checked.</p>
        </div>
        <div className="faq">
          <p className="question"><FontAwesomeIcon className="icon" icon={faPlus} />Can I connect other calendar apps?</p>
          <p className="answer">Yes! Google Calendar is currently supported, with more integrations coming soon.</p>
        </div>
        <div className="faq">
          <p className="question"><FontAwesomeIcon className="icon" icon={faPlus} />Is my data secure?</p>
          <p className="answer">Yes! Simple Schedule uses secure servers to protect your data and does not sell your personal information.</p>
        </div>
        <div className="faq">
          <p className="question"><FontAwesomeIcon className="icon" icon={faPlus} />Who owns the data?</p>
          <p className="answer">You do. You can delete your data—or your entire account—anytime from the profile section of the app.</p>
        </div>
        <div className="faq">
          <p className="question"><FontAwesomeIcon className="icon" icon={faPlus} />How do I get access?</p>
          <p className="answer">Simple Schedule is currently in private beta. Request access by sending us a <a href="mailto:johannes.tacskovics@gmail.com">message!</a></p>
        </div>
      </div>
    </div>
  );
};

export default Install;