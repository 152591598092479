import React, { useEffect, useLayoutEffect, useState, useRef, useContext, useCallback } from 'react';
import moment from 'moment'
import { ProfileContext } from '../contexts/ProfileContext';
import { FeedContext } from '../contexts/FeedContext';
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCalendarWeek, faCalendarDay, faClock, faBoltLightning, faEye, faEyeSlash, faCalendarXmark, faQuestion, faFileCircleXmark, faSquareArrowUpRight, faXmark, faArrowCircleLeft, faCircleInfo, faSun, faTemperatureArrowUp, faTemperatureDown, faTemperatureHalf, faWind } from '@fortawesome/free-solid-svg-icons';
import { getDomain, truncateText } from '../utils/FormatUtils';
import DisclosureComponent from './DisclosureComponent';
import EventIconLabel from '../components/EventIconLabel';
import PersonComponent from './PersonComponent';
import '../styles/EventComponent.css'
import 'weather-icons/css/weather-icons.css';
import { faCalendar, faCircleXmark, faEnvelope } from '@fortawesome/free-regular-svg-icons';

const EventComponent = ({ eventId, closeEvent, openMessage, scrollToDay, resetFeedScroll, disableFeedScroll }) => {
    const { userProfile } = useContext(ProfileContext);
    const { events, updateEvent, demoMode, getForecast } = useContext(FeedContext);
    const { showPrompt, showToast } = useGlobalPrompts();
    const [event, setEvent] = useState(null);
    const [day, setDay] = useState(null);
    const eventIdRef = useRef(null);
    const overlayRef = useRef(null);
    const drawerRef = useRef(null);
    const shadowRef = useRef(null);
    const baseRef = useRef(null);
    const boltCoachmark = useRef(null);
    const sourcesCoachmark = useRef(null);
    const descriptionRef = useRef(null);
    const calendarRef = useRef(null);
    const isDraggingRef = useRef(false);
    const debounceRef = useRef(false);
    const startYRef = useRef(0);
    const OPEN = 'open';
    const CLOSED = 'closed';
    const MAXIMIZED = 'maximized';
    const currentPositionRef = useRef(CLOSED);
    const [like, setLike] = useState(null);
    const [dislike, setDislike] = useState(null);
    const [firstView, setFirstView] = useState(null);
    const [isClampAdjusted, setIsClampAdjusted] = useState(false);
    const [disclosureOpen, setDisclosureOpen] = useState(false);
    const [hideLabel, setHideLabel] = useState(null);
    const [hideIcon, setHideIcon] = useState(faEyeSlash);
    const [weather, setWeather] = useState(null);
    const [gcalConnected, setGcalConnected] = useState(false);
    const [gcalEvents, setGcalEvents] = useState(null);
    const [gcalEventsCount, setGcalEventsCount] = useState(0);
    const [gcalFetching, setGCalFetching] = useState(false);
    const refreshGcalRef = useRef(true);

    useEffect(() => {
        if (events && eventId) {
            const event = events.get(eventId);
            if (event) {
                if (eventIdRef.current !== eventId) { // Only update if the event ID has changed
                    eventIdRef.current = eventId;

                    console.log('UseEffect Event is Set')

                    setEvent(event);
                    setLike(event.like);
                    setDislike(event.dislike);

                    const day = moment(event.startLocal).startOf('day').format('YYYY-MM-DD');
                    setDay(day);

                    const weather = getForecast(day);
                    setWeather(weather);

                    const isGCalConnected = userProfile?.calendarProvider == 'gcal';
                    setGcalConnected(isGCalConnected);

                    if (isGCalConnected) {
                        fetchGCalEvents();
                    }
                }
            }
        }
    }, [events, eventId]);

    const fetchGCalEvents = () => {
        if (currentPositionRef.current !== MAXIMIZED ||
            !day ||
            !refreshGcalRef.current
        ) {
            return;
        }

        const updateEvents = gcalEventsCount > 0;

        setGCalFetching(true);

        fetch('/api/calendar/' + day, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (!data.events) {
                    throw new Error('No events data received');
                }

                if (updateEvents && gcalEvents) {
                    // Map through new events and update or preserve existing events
                    const updatedEvents = data.events.map(newEvent => {
                        const existingEvent = gcalEvents.find(e => e.id === newEvent.id);
                        return {
                            ...newEvent,
                            added: existingEvent ? existingEvent.added : true, // Only set added:true if it's a new event
                            removed: undefined // Clear any 'removed' flag
                        };
                    });

                    setGcalEvents(updatedEvents);
                } else {
                    // Initial load - set all events without added flag
                    setGcalEvents(data.events);
                }

                refreshGcalRef.current = false;
                setGCalFetching(false);
                setGcalEventsCount(parseInt(data.count) || 0);
            })
            .catch(error => {
                console.error('Error fetching calendar events:', error);
                setGCalFetching(false);
                setGcalEvents(null);
                setGcalEventsCount(0);
            });
    };

    // Function to open hyperlink in a new window
    const handleOpenLink = (hyperlink) => {
        if (hyperlink) {
            window.open(hyperlink, "_blank", "noopener,noreferrer");
        }
    };

    const toggleDisclosure = (openState) => {
        if (!demoMode) {
            setDisclosureOpen(openState)
        }
    }

    const handleDislikeClick = (dislikeReason) => {
        const toggle = !dislike; // Toggle the dislike value

        if (!toggle) {
            setHideLabel(labelHide);
            setHideIcon(faEyeSlash);
        } else {
            setHideLabel(labelUnhide);
            setHideIcon(faEye);
        }

        if (demoMode) {
            boltCoachmark.current.classList.add('hide');
            setDislike(toggle);
            updateEvent({ ...event, dislike: toggle });
            return;
        }

        const eventId = event.originalId ? event.originalId : event._id;
        fetch('/api/events/' + eventId + '/dislike', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ toggle, dislikeReason })
        })
            .then(response => {
                if (response.ok) {
                    setDislike(toggle);
                    updateEvent({ ...event, dislike: toggle });

                    refreshGcalRef.current = true;
                    fetchGCalEvents();
                } else {
                    // Handle errors here
                    console.error('Failed to update dislike');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

        setDisclosureOpen(false);
    }

    const handleLikeClick = () => {
        const toggle = !like;

        // Show demo mode coachmark
        if (demoMode) {
            boltCoachmark.current.classList.remove('hide');
            setLike(toggle);
            updateEvent({ ...event, like: toggle });

            return;
        }

        // Show prompt to connect to Google Calendar
        if (!userProfile.calendarProvider) {
            showPrompt({
                icon: <img src="/gcallogo.svg" />,
                title: 'Save to Google Calendar',
                description: <span>Connect to save events to your Google Calendar with one touch</span>,
                onConfirm: () => { window.location.href = '/join/calendar' },
                confirmMessage: 'Connect',
                cancelMessage: 'Maybe Later',
            });

            return;
        }

        // Update the like status
        const eventId = event.originalId ? event.originalId : event._id;
        fetch('/api/events/' + eventId + '/like', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ toggle: toggle }),
        })
            .then(response => {
                if (response.ok) {
                    setLike(toggle);
                    updateEvent({ ...event, like: toggle });
                    const eventDetails = truncateText(event.eventName, 16) + ' on ' + moment(event.startLocal).format("MMM D")
                    if (toggle) {
                        showToast({
                            icon: <img src="/gcallogo.svg" />,
                            title: 'Added to Google Calendar',
                            description: eventDetails + ' was added'
                        });
                    } else {
                        showToast({
                            icon: <img src="/gcallogo.svg" />,
                            title: 'Removed from Google Calendar',
                            description: eventDetails + ' was removed'
                        });
                    }

                    refreshGcalRef.current = true;
                    fetchGCalEvents();
                } else {
                    // Handle errors here
                    showToast({
                        icon: '❌',
                        title: 'Error',
                        description: "Oops, this event wasn't added to Google Calendar"
                    });
                    console.error('Failed to update likes');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const labelHide = 'Hide Event';
    const labelUnhide = "Unhide Event";
    const labelDateIncorrect = 'Wrong date or time'
    const labelDetailsIncorrect = 'Details are incorrect'
    const labelIrrelevant = 'Irrelevant info'

    const disclosureItems = [
        { label: hideLabel, icon: hideIcon, onClick: () => handleDislikeClick(null) },
        { label: labelDateIncorrect, icon: faCalendarXmark, onClick: () => handleDislikeClick(labelDateIncorrect) },
        { label: labelDetailsIncorrect, icon: faFileCircleXmark, onClick: () => handleDislikeClick(labelDetailsIncorrect) },
        { label: labelIrrelevant, icon: faQuestion, onClick: () => handleDislikeClick(labelIrrelevant) },
    ];

    const defaultOpacity = 0.5;
    const maximizedHeightRef = useRef(null);
    const openHeightRef = useRef(null);
    const eventContentRef = useRef(null);

    const getRemPropertyInPx = (element, property) => {
        if (!element) return null;

        const remValue = parseFloat(getComputedStyle(element).getPropertyValue(property));
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        return remValue * rootFontSize; // Convert rem to px
    };

    const touchStartHandlerRef = useRef();
    const touchMoveHandlerRef = useRef();
    const touchEndHandlerRef = useRef();

    useEffect(() => {

        const drawer = drawerRef.current;
        const overlay = overlayRef.current;
        const shadow = shadowRef.current;
        const content = eventContentRef.current;
        // Ensure the drawer ref is not null
        if (!drawer || !event || !content) {
            return;
        }
        if (currentPositionRef.current === OPEN ||
            currentPositionRef.current === MAXIMIZED) {
            return;
        }

        currentPositionRef.current = OPEN;
        openHeightRef.current = getRemPropertyInPx(content, "--open-height");
        maximizedHeightRef.current = getRemPropertyInPx(content, "--maximized-height");

        console.log('drawerShown');
        console.log(event.tags);

        if (!dislike) {
            setHideLabel(labelHide);
            setHideIcon(faEyeSlash);
        } else {
            setHideLabel(labelUnhide);
            setHideIcon(faEye);
        }

        const handleTouchStart = (e) => {
            if (debounceRef.current) {
                return;
            }

            console.log('handleTouchStart')
            drawer.style.bottom = `0px`;
            drawer.style.animation = 'none' // Clear animation when starting the drag
            drawer.style.transition = ''; // Remove the transition to avoid interference with other animations or drags
            startYRef.current = e.touches[0].clientY; // Use ref to store startY
            isDraggingRef.current = true;
        };

        const handleTouchMove = (e) => {
            if (!isDraggingRef.current) {
                return
            }

            console.log('handleTouchMove');
            e.preventDefault();

            const drawerBottom = parseFloat(getComputedStyle(drawer).bottom);
            const drawerHeight = parseFloat(getComputedStyle(drawer).height);
            const opacity = ((drawerHeight + drawerBottom) / drawerHeight) * defaultOpacity;

            const clientY = e.touches[0].clientY;
            const startDiffY = startYRef.current - clientY;

            content.style.animation = 'none';
            content.style.transition = 'none';
            drawer.style.animation = 'none';
            drawer.style.transition = 'none';

            if (currentPositionRef.current === MAXIMIZED) {
                if (startDiffY <= 0) {
                    const newContentHeight = maximizedHeightRef.current + startDiffY;

                    if (newContentHeight > openHeightRef.current) {

                        content.style.height = "auto"; // Reset
                        requestAnimationFrame(() => {
                            content.style.height = newContentHeight + 'px';
                        });
                        shadowRef.current.style.opacity = defaultOpacity;

                    } else if (newContentHeight <= openHeightRef.current) {
                        const drawerBottom = startDiffY + (maximizedHeightRef.current - openHeightRef.current);
                        // Move the drawer exactly as much as the diffY value
                        drawer.style.bottom = `${drawerBottom}px`;
                        // Adjust tint of overlay
                        shadowRef.current.style.opacity = opacity;
                    }

                }

            } else if (currentPositionRef.current === OPEN) {
                if (startDiffY <= 0) {
                    // Move the drawer exactly as much as the diffY value
                    drawer.style.bottom = `${startDiffY}px`;

                    // Adjust tint of overlay
                    shadowRef.current.style.opacity = opacity;

                } else if (startDiffY > 0) {
                    // Move drawer to top position at the end of movement
                    let contentHeight = openHeightRef.current + startDiffY;
                    if (contentHeight > maximizedHeightRef.current) {
                        contentHeight = maximizedHeightRef.current;
                    }

                    content.style.height = contentHeight + 'px';

                    // Adjust tint of overlay
                    shadowRef.current.style.opacity = defaultOpacity;
                }
            }

        };


        const handleTouchEnd = () => {
            if (!isDraggingRef.current) {
                return;
            }

            console.log('handleTouchEnd')
            isDraggingRef.current = false;

            const swipeDistance = 40;
            const drawerBottom = Math.abs(parseFloat(getComputedStyle(drawer).bottom));
            const contentHeight = parseFloat(getComputedStyle(content).height);
            const closeToMaximized = maximizedHeightRef.current - swipeDistance //openHeightRef.current + (maximizedHeightRef.current - openHeightRef.current) / 2
            const closeToClosed = swipeDistance;

            // Check if the drawer has been dragged beyond the halfway point of its height
            if (currentPositionRef.current === MAXIMIZED) {
                if (contentHeight >= closeToMaximized) {
                    maximizeDrawer();

                } else if (
                    contentHeight < closeToMaximized &&
                    drawerBottom < closeToClosed
                ) {
                    openDrawer();

                } else if (drawerBottom > closeToClosed) {
                    hideDrawer();
                }

            } else if (currentPositionRef.current === OPEN) {

                if (contentHeight > openHeightRef.current) {
                    maximizeDrawer();

                } else if (drawerBottom > closeToClosed) {
                    hideDrawer();

                } else {
                    openDrawer();
                }
            }
        };

        // Attach event listeners
        drawer.addEventListener('touchstart', handleTouchStart);
        drawer.addEventListener('touchmove', handleTouchMove);
        drawer.addEventListener('touchend', handleTouchEnd);

        // Add event loaded to div. This causes the drawer to animate up.
        if (overlay && shadow) {
            overlay.classList.add('event-loaded');
            shadow.style.opacity = defaultOpacity;
        }


        const handleCalendarTouchStart = (e) => {
            disableFeedScroll();
            if (calendarRef.current === e.target || calendarRef.current?.contains(e.target)) {
                e.stopPropagation();
            } else {
                e.preventDefault(); // Prevent scrolling on non-calendar areas
            }
        };
        
        const handleCalendarTouchMove = (e) => {
            disableFeedScroll();
            if (calendarRef.current === e.target || calendarRef.current?.contains(e.target)) {
                const calendar = calendarRef.current;
                const isScrollable = calendar.scrollHeight > calendar.clientHeight;
                
                if (!isScrollable) {
                    e.preventDefault();
                }
                e.stopPropagation();
            } else {
                e.preventDefault(); // Prevent scrolling on non-calendar areas
            }
        };

        const handleCalendarTouchEnd = (e) => {
            if (calendarRef.current === e.target || calendarRef.current?.contains(e.target)) {
                e.stopPropagation();
            } else {
                e.preventDefault(); // Prevent scrolling on non-calendar areas
            }
            resetFeedScroll();
        };

        if (calendarRef.current) {
            touchStartHandlerRef.current = handleCalendarTouchStart;
            touchMoveHandlerRef.current = handleCalendarTouchMove;
            touchEndHandlerRef.current = handleCalendarTouchEnd;

            // Set passive: false since we call preventDefault() in these handlers
            calendarRef.current.addEventListener('touchstart', touchStartHandlerRef.current, { passive: false });
            calendarRef.current.addEventListener('touchmove', touchMoveHandlerRef.current, { passive: false });
            calendarRef.current.addEventListener('touchend', touchEndHandlerRef.current, { passive: true });
        }

        return () => {
            resetFeedScroll();

            if (drawer) {
                drawer.removeEventListener('touchstart', handleTouchStart);
                drawer.removeEventListener('touchmove', handleTouchMove);
                drawer.removeEventListener('touchend', handleTouchEnd);
            }

            if (calendarRef.current) {
                calendarRef.current.removeEventListener('touchstart', touchStartHandlerRef.current, { passive: false });
                calendarRef.current.removeEventListener('touchmove', touchMoveHandlerRef.current, { passive: false });
                calendarRef.current.removeEventListener('touchend', touchEndHandlerRef.current, { passive: true });
            }

        };
    }, [event]);


    const openDrawer = () => {
        const content = eventContentRef.current;
        const drawer = drawerRef.current;

        if (!drawer || !content) {
            return;
        }

        console.log('openDrawer');
        debounceRef.current = true;
        currentPositionRef.current = OPEN;

        // Set initial state and transition in same frame
        requestAnimationFrame(() => {
            // Apply transitions first
            drawer.style.transition = 'bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1)';
            content.style.transition = 'height 0.3s cubic-bezier(0.4, 0, 0.2, 1)';

            // Then set positions
            drawer.style.bottom = '0px';
            content.style.height = openHeightRef.current + 'px';
            shadowRef.current.style.opacity = defaultOpacity;

            // Clear transitions after animation completes
            setTimeout(() => {
                debounceRef.current = false;
            }, 300);
        });
    };

    const maximizeDrawer = () => {
        const content = eventContentRef.current;
        const drawer = drawerRef.current;

        if (!drawer || !content) {
            return;
        }

        console.log('maximizeDrawer');
        debounceRef.current = true;
        currentPositionRef.current = MAXIMIZED;

        fetchGCalEvents();

        requestAnimationFrame(() => {
            drawer.style.transition = 'bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1)';
            content.style.transition = 'height 0.3s cubic-bezier(0.4, 0, 0.2, 1)';

            drawer.style.bottom = '0px';
            content.style.height = maximizedHeightRef.current + 'px';
            shadowRef.current.style.opacity = defaultOpacity;

            setTimeout(() => {
                debounceRef.current = false;
            }, 300);
        });
    };

    const hideDrawer = () => {

        const drawer = drawerRef.current;
        if (!drawer) {
            return;
        }

        console.log('hideDrawer');
        debounceRef.current = true;
        currentPositionRef.current = CLOSED;

        // Clear any conflicting styles
        drawer.style.animation = 'none';
        drawer.style.transition = 'none'; // Temporarily remove transition

        // Force a reflow (this ensures the new transition takes effect)
        const height = drawer.offsetHeight;

        // Apply the transition and hide the drawer
        drawer.style.transition = 'bottom 0.3s ease-in-out';
        drawer.style.bottom = '-100%'; // Target position

        shadowRef.current.style.opacity = 0;

        setTimeout(() => {
            drawer.style.animation = 'none';
            drawer.style.transition = 'none';
            debounceRef.current = false;
            closeEvent(); // Close after transition is complete
        }, 300);
    }

    const postFirstView = () => {
        if (demoMode) {
            setFirstView(true);
            updateEvent({ ...event, firstView: true });
            return;
        }

        if (event && !event.firstView && !demoMode) { // Only set once
            const eventId = event.originalId ? event.originalId : event._id;

            fetch('/api/events/' + eventId + '/firstview', {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ firstView: true }),
            })
                .then(response => {
                    if (response.ok) {
                        setFirstView(true);
                        updateEvent({ ...event, firstView: true });
                    } else {
                        // Handle errors here
                        console.error('Failed to update firstView');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    useLayoutEffect(() => {

        postFirstView(); // Logs first view of the event

        if (descriptionRef.current && baseRef.current && !isClampAdjusted) {
            // Get the bounding rectangles of both the parent (eventRef) and the child (descriptionRef)
            const parentRect = baseRef.current.getBoundingClientRect();
            const descriptionRect = descriptionRef.current.getBoundingClientRect();

            // Check if the description is clipped by comparing their bottom edges
            const isClipped = descriptionRect.bottom > parentRect.bottom;

            if (isClipped) {
                // Calculate how much of the description is clipped
                const clipAmount = Math.ceil(descriptionRect.bottom - parentRect.bottom);
                const descriptionHeight = Math.ceil(descriptionRef.current.getBoundingClientRect().height);
                const computedStyle = window.getComputedStyle(descriptionRef.current);
                const padding = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
                const margin = parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom);
                let lineHeight = parseFloat(computedStyle.lineHeight); //lineHeight set in CSS

                // Default to font size multiplier if line height is invalid
                //const fontSize = parseFloat(computedStyle.fontSize);
                //if (isNaN(lineHeight) || lineHeight === 0) {
                //    lineHeight = fontSize * 1.3;
                //}

                const numberOfLines = Math.round((descriptionHeight - margin - padding) / lineHeight);
                const linesClipped = Math.ceil(clipAmount / lineHeight);
                const newLineClamp = numberOfLines - linesClipped;

                // Apply the adjusted line clamp
                descriptionRef.current.style.webkitLineClamp = newLineClamp;
                setIsClampAdjusted(true);
            }
        }
    }, [event]);

    const renderHyperlink = () => {
        if (!event) {
            return null;
        }

        if (event.hyperlink) {
            return event.hyperlink;
        }

        // Loop through mentions
        for (let mention of event.mentions) {
            if (mention.sourceURL) {
                return mention.sourceURL;
            }
        }

        return null;  // Return null if no hyperlink found
    };

    const hyperlink = renderHyperlink();

    const renderMentioners = (mention, index) => {
        return (
            <PersonComponent
                key={mention._id}
                sender={mention.sourceSender}
                zIndex={500 - index}
                onClick={(e) => {
                    e.preventDefault();
                    openMessage(mention.sourceContentId, mention.sourceType, eventId);
                    hideDrawer();
                }}
            />
        );
    };

    const renderMentionNote = (event) => {

        const mentionDates = [];

        let message = (event.mentions.length > 1 ? event.mentions.length + ' mentions' : '1 mention') + " ";

        for (const mention of event.mentions) {
            mentionDates.push(moment(mention.sourceDate).fromNow())
        }

        const uniqueMentionDates = [...new Set(mentionDates)];

        if (uniqueMentionDates.length == 1) {
            message += uniqueMentionDates[0];
        } else if (uniqueMentionDates.length == 2) {
            message += uniqueMentionDates.join(' and ')
        } else if (uniqueMentionDates.length > 2) {
            message += uniqueMentionDates.slice(0, -1).join(', ') + ', and ' + uniqueMentionDates[uniqueMentionDates.length - 1];
        }

        return message;
    }

    const renderGCalEvents = useCallback((event) => {
        return (
            <>
                <div className="title">
                    Google Calendar - {moment(event.startLocal).format('MMMM D')}
                </div>
                {gcalConnected ? (
                    <div className="calendar" ref={calendarRef}>
                        {gcalEventsCount > 0 ? (
                            <>
                                {gcalEvents.map((gcalEvent, index) => (
                                    <div className={`ext-event${gcalEvent.eventId ? ' saved' : ''}${gcalEvent.added ? ' added' : ''}`} key={index}>
                                        <div className='title'>{gcalEvent.summary}</div>
                                        <div className='time'>
                                            {gcalEvent.range && moment(gcalEvent.start).format('MMM. D')}{gcalEvent.range && (' - ' + moment(gcalEvent.end).format('MMM. D'))}
                                            {!gcalEvent.dateTime && !gcalEvent.range && 'All Day'}
                                            {!gcalEvent.range && gcalEvent.dateTime && moment(gcalEvent.start).format('h:mm a')}{!gcalEvent.range && gcalEvent.dateTime && (' - ' + moment(gcalEvent.end).format('h:mm a'))}
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            gcalFetching ? (
                                <div className='ext-event no-events'>
                                    <div className='title'>Getting Google Events...</div>
                                </div>
                            ) : (
                                <div className='ext-event no-events'>
                                    <div className='title'>
                                        <FontAwesomeIcon className="icon" icon={faCalendar} /> No events found
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                ) : (
                    <div className='connect-gcal'>
                        <button onClick={() => { window.location.href = '/join/calendar' }}>
                            Allow Access
                        </button>
                    </div>
                )}
            </>
        );
    }, [gcalFetching, gcalConnected, gcalEvents, gcalEventsCount]);

    const renderedContent = (
        <div className='event-drawer-overlay' ref={overlayRef}>
            <div className="event-drawer" ref={drawerRef} >
                {event !== null && (
                    <div className="event-content" ref={eventContentRef} >
                        <div className="close-handle" ></div>
                        <button className="close-btn" onClick={hideDrawer} ><FontAwesomeIcon className="icon" icon={faXmark} /></button>
                        <div className="header">
                            <EventIconLabel firstView={true} eventDetails={event.eventName + ' ' + event.description} includeLabel={true} includeDefaultLabel={true} tags={event.tags} />
                        </div>
                        <div className="event" >
                            <div className="base-content" ref={baseRef}>
                                <DisclosureComponent
                                    isOpen={disclosureOpen}
                                    toggleDisclosure={toggleDisclosure}
                                    disclosureItems={disclosureItems}
                                />
                                <div className="title">
                                    {event.eventName}
                                </div>
                                {event.monthEvent ? (
                                    <div className="month">
                                        <FontAwesomeIcon className="icon" icon={faCalendarDays} />
                                        {`Month of ${moment(event.startLocal).startOf('month').format('MMMM')}`}
                                    </div>
                                ) : null}
                                {event.weekEvent ? (
                                    <div className="week">
                                        <FontAwesomeIcon className="icon" icon={faCalendarWeek} />
                                        {`${moment(event.startLocal).startOf('week').format('ddd, MMM D')} - ${moment(event.startLocal).endOf('week').format('ddd, MMM D')}`}
                                    </div>
                                ) : null}
                                {!event.monthEvent && !event.range ? (
                                    <div className="day">
                                        <FontAwesomeIcon className="icon" icon={faCalendarDay} />
                                        {event.startLocal ? moment(event.startLocal).format('dddd, MMMM D') : ''}{moment(event.startLocal).format('YYYY') === moment().format('YYYY') ? '' : ', ' + moment(event.startLocal).format('YYYY')}
                                    </div>
                                ) : null}
                                {!event.monthEvent && event.range ? (
                                    <div className="day">
                                        <FontAwesomeIcon className="icon" icon={faCalendarWeek} />
                                        {moment(event.startRangeLocal).format('MMMM Do')} - {moment(event.endRangeLocal).format('MMMM Do')}</div>
                                ) : null}
                                {event.dateTime && event.startLocal ? (
                                    <div className="start">
                                        <FontAwesomeIcon className="icon" icon={faClock} />
                                        {moment(event.startLocal).format('h:mm a')}
                                    </div>
                                ) : null}
                                {event.dateTime && event.endLocal ? (
                                    <div className="end">
                                        {` - ${moment(event.endLocal).format('h:mm a')}`}
                                    </div>
                                ) : null}
                                <div className="description" ref={descriptionRef} >
                                    {event.description}
                                </div>
                            </div>
                            <div className='extended-content'>
                                <div className="divider" />
                                {weather && (<>
                                    <div className="title">
                                        {weather.today ? 'Current Weather' : 'Weather Forecast'}
                                    </div>
                                    <div className='weather'>
                                        <div className="forecast" >
                                            <div className={`symbol wi ${weather.symbol}`} />{weather.code}{weather.precipitationChance > 0 ? ' ' + Math.round(weather.precipitationChance * 100) + '%' : ''}
                                            {weather.today ?
                                                <>
                                                    <FontAwesomeIcon className="icon temp" icon={faTemperatureHalf} />{weather.temp}{weather.tempUnit.toUpperCase()}
                                                    <FontAwesomeIcon className="icon uv" icon={faSun} />UV: {weather.uvIndex}
                                                    <FontAwesomeIcon className="icon wind" icon={faWind} />{weather.windSpeed} {weather.speedUnit}
                                                </>
                                                :
                                                <>
                                                    <FontAwesomeIcon className="icon low" icon={faTemperatureDown} />{weather.min}{weather.tempUnit.toUpperCase()}
                                                    <FontAwesomeIcon className="icon high" icon={faTemperatureArrowUp} />{weather.max}{weather.tempUnit.toUpperCase()}
                                                    <FontAwesomeIcon className="icon uv" icon={faSun} />UV: {weather.uvIndex}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                                )}
                                {renderGCalEvents(event)}
                            </div>
                            <div className="action">
                                {hyperlink ? (
                                    <button className="go" onClick={(e) => { handleOpenLink(hyperlink); }}>
                                        <span className="label">Go</span><FontAwesomeIcon className="icon" icon={faSquareArrowUpRight} />
                                        <div className="hint">{getDomain(hyperlink)}</div>
                                    </button>
                                ) : null}
                                <div className="right-action">
                                    <button onClick={(e) => { handleLikeClick() }} className={`like ${like ? 'true' : 'false'}`}>
                                        <div className="fill"><FontAwesomeIcon className='icon' icon={faBoltLightning} /></div>
                                    </button>
                                </div>
                                {demoMode && (
                                    <div className={`bolt coachmark ${like ? '' : 'hide'}`} ref={boltCoachmark}>
                                        <FontAwesomeIcon className="icon" icon={faCircleInfo} />
                                        <div className="instruction">Connect Google Calendar <br />to&nbsp; save events externally</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="sources">
                            <div className="source-messages">
                                {event.mentions.length > 0 ? (
                                    event.mentions.map((mention, index) =>
                                        renderMentioners(mention, index)
                                    )
                                ) : (
                                    renderMentioners(event.mentions[0], 0)
                                )}
                            </div>
                            <span className="note"><FontAwesomeIcon icon={faEnvelope} /> {renderMentionNote(event)}</span>
                            {demoMode && (
                                <div className="coachmark" ref={sourcesCoachmark}>
                                    <FontAwesomeIcon className="icon" icon={faArrowCircleLeft} />
                                    <div className="instruction">
                                        Tap to see the email for this event
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="shadow" ref={shadowRef} onClick={hideDrawer}></div>
        </div>
    );

    return renderedContent;
};

export default EventComponent;