import React, { useContext } from 'react';
import SuccessIcon from '../assets/SuccessIcon';
import { useNavigate } from 'react-router-dom';
import { ProfileContext } from '../contexts/ProfileContext';

const JoinSuccess = () => {
  const { userProfile } = useContext(ProfileContext);
  const navigate = useNavigate();

  const advance = () => {
    if (userProfile && userProfile.preferences && !userProfile.preferences.firstViewTutorial) {
      navigate(`/tutorial`);
    } else {
      navigate(`/focus`);
    }
  }

  return (
    <div className='success onboarding'>
      <div className="onboarding-title"><h1>Ready</h1></div>
      <div className="onboarding-main">
        <SuccessIcon size={120} color="#ff6700" />
      </div>
      <div className="onboarding-continue">
        <button className='continue' onClick={advance}>Continue</button>
      </div>
    </div>
  );
};

export default JoinSuccess;
