import React from 'react';
import { getFirstTwoInitials, getColors } from '../utils/FormatUtils';
import '../styles/PersonComponent.css';

const PersonComponent = ({ 
    sender, 
    onClick, 
    zIndex = 0,
    className = ''
}) => {
    const firstTwo = getFirstTwoInitials(sender);
    const colors = getColors(firstTwo);

    return (
        <div
            className={`person-component ${className}`}
            onClick={onClick}
            style={{
                background: `linear-gradient(100deg, ${colors.primary} 0%, ${colors.tertiary} 70%, ${colors.quaternary} 100%)`,
                border: `0.1rem solid ${colors.secondary}`,
                zIndex
            }}
        >
            <div className="initials">{firstTwo}</div>
        </div>
    );
};

export default PersonComponent;