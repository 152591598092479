import React from 'react';
import PromptComponent from './PromptComponent';
import { faArrowUpFromBracket, faBars, faEllipsisVertical, faMobile, faMobilePhone, faMobileScreen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import '../styles/InstallPrompt.css';

const InstallPrompt = ({
    icon,
    title,
    description,
    onConfirm,
    confirmMessage,
    onCancel,
    cancelMessage,
    onClose,
}) => {

    const isMobileSafari = () => {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        return iOS && webkit && !ua.match(/CriOS/i);
    };

    const isMobileChrome = () => {
        const ua = window.navigator.userAgent;
        return /CriOS/i.test(ua);
    };

    const isAndroidChrome = () => {
        const ua = window.navigator.userAgent;
        return /Android.*Chrome\/[.0-9]*/.test(ua) && !isSamsungInternet();
    };
    
    const isSamsungInternet = () => {
        const ua = window.navigator.userAgent;
        return /SamsungBrowser/.test(ua);
    };

    const returnPrompt = () => {

        if (isMobileSafari()) {
            console.log('ios safari');
            return (
                <div className="install-prompt ios safari">
                    <PromptComponent
                        icon={icon}
                        title='Add to Home Screen'
                        description={<>
                            <p className='detail'>To install and use the app, you need to add <br />this website to your home screen.</p>
                            <div className='instruction'>
                                1. In Safari, tap the share icon <FontAwesomeIcon icon={faArrowUpFromBracket} /> below<br />
                                2. Swipe up, tap Add to Home Screen <FontAwesomeIcon icon={faSquarePlus} />
                            </div>
                        </>}
                        confirmMessage={confirmMessage}
                        cancelMessage={cancelMessage}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        onClose={onClose}
                    />
                </div>
            )


        } else if (isMobileChrome()) {
            console.log('ios chrome');
            return (
                <div className="install-prompt ios chrome">
                    <PromptComponent
                        icon={icon}
                        title='Add to Home Screen'
                        description={<>
                            <p className='detail'>To install and use the app, you need to add <br />this website to your home screen.</p>
                            <div className='instruction'>
                                1. In Chrome, tap the share icon <FontAwesomeIcon icon={faArrowUpFromBracket} /> above<br />
                                2. Swipe up, tap Add to Home Screen <FontAwesomeIcon icon={faSquarePlus} />
                            </div>
                        </>}
                        confirmMessage={confirmMessage}
                        cancelMessage={cancelMessage}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        onClose={onClose}
                    />
                </div>
            )

        } else if (isAndroidChrome()) {
            console.log('android chrome');
            return (
                <div className="install-prompt android chrome">
                    <PromptComponent
                        icon={icon}
                        title='Add to Home screen'
                        description={<>
                            <p className='detail'>To install and use the app, you need to add <br />this website to your home screen.</p>
                            <div className='instruction'>
                                1. In Chrome, tap the more icon <FontAwesomeIcon icon={faEllipsisVertical} /> above<br />
                                2. Tap Add to Home screen <FontAwesomeIcon icon={faMobile} />
                            </div>
                        </>}
                        confirmMessage={confirmMessage}
                        cancelMessage={cancelMessage}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        onClose={onClose}
                    />
                </div>
            )

        } else if (isSamsungInternet()) {
            console.log('android samsung');
            return (
                <div className="install-prompt android samsung">
                    <PromptComponent
                        icon={icon}
                        title='Add to Home screen'
                        description={<>
                            <p className='detail'>To install and use the app, you need to add <br />this website to your home screen.</p>
                            <div className='instruction'>
                                1. In Samsung Internet, tap the more icon <FontAwesomeIcon icon={faBars} /><br />
                                2. Tap Add to page to <FontAwesomeIcon icon={faPlus} /><br />
                                3. Tap Home screen
                            </div>
                        </>}
                        confirmMessage={confirmMessage}
                        cancelMessage={cancelMessage}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        onClose={onClose}
                    />
                </div>
            )

        } else {
            console.log('default');
            return (
                <div className="install-prompt">
                    <PromptComponent
                        icon={<FontAwesomeIcon icon={faMobileScreen} className='icon'/>}
                        title='Designed for Mobile'
                        description={<><p>Simple Schedule is built for your mobile phone.<br/>Open this site on your phone to continue.</p></>}
                        confirmMessage={confirmMessage}
                        cancelMessage={cancelMessage}
                        onConfirm={null}
                        onCancel={onCancel}
                        onClose={onClose}
                    />
                </div>
            )
        }
    }

    return returnPrompt();
};

export default InstallPrompt;