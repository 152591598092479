import React, { useEffect, useState, useRef, useContext } from 'react';

import moment from 'moment'
import axios from 'axios';
import DOMPurify from 'dompurify';
import { extendMoment } from 'moment-range';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCalendarDay, faEnvelopeOpenText, faExpand, faCircleNotch, faFileCircleCheck, faCalendarPlus, faFileArrowUp, faHourglassStart } from '@fortawesome/free-solid-svg-icons';

import CardComponent from "./CardComponent";
import PersonComponent from './PersonComponent';
import { ProfileContext } from '../contexts/ProfileContext';
import { FeedContext } from '../contexts/FeedContext';
import { EventTime } from '../utils/FormatDates';

import '../styles/MessageComponent.css'

const MessageComponent = ({ sourceId, sourceType, eventId, closeMessage, openEvent }) => {
    const extendedMoment = extendMoment(moment);

    const { userProfile } = useContext(ProfileContext);
    const { events, fetchEvents, demoMode, demoMessage } = useContext(FeedContext);

    const [messageData, setMessageData] = useState(null);
    const [srcMode, setSrcMode] = useState(null);
    const [title, setTitle] = useState(null);
    const [date, setDate] = useState(null);
    const [html, setHtml] = useState(null);
    const [urlPath, setUrlPath] = useState(null);
    const [sender, setSender] = useState('');
    const [address, setAddress] = useState('');
    const [fetchedEvents, setFetchedEvents] = useState();
    const [fetchedEventCount, setFetchedEventCount] = useState(0);
    const [futureEventsCount, setFutureEventsCount] = useState(0);
    const [icon, setIcon] = useState(faExpand); // Initially set to faExpand
    const rescanButtonRef = useRef(null);
    const eventsCoachmark = useRef();


    const openSourceEvent = (id) => {
        if (demoMode) {
            //eventsCoachmark.current.classList.remove('hide');
        }
        openEvent(id);
    }

    const rescanMessage = async () => {
        if (demoMode) {
            return;
        }

        setIcon(faCircleNotch);

        if (rescanButtonRef.current) {
            rescanButtonRef.current.classList.add('active'); // Example class toggle
        }

        try {
            await axios.post(`/api/messages/${sourceId}/reset`); //TODO replace this with FETCH and credentials:include
            await fetchEvents();
            setIcon(faExpand);
            //closeDrawer();
            //reinitializeComponent();

        } catch (error) {
            console.error('Failed to rescan message:', error);
        }

        if (rescanButtonRef.current) rescanButtonRef.current.classList.remove('active');
    };

    const disclosureItems = [
        { label: 'Rescan Message', icon: icon, onClick: rescanMessage, ref: rescanButtonRef }
    ];

    const countFutureEvents = (eventsData) => {
        let futureCount = 0;

        for (let event of eventsData) {
            if (event.startLocal && moment(event.startLocal).isAfter(moment())) {
                futureCount++;
            }
        }

        return futureCount;
    }

    // Fetch data function
    const fetchData = async (controller) => {

        const { signal } = controller;

        if (demoMode) {
            setSrcMode('email');
            setMessageData(demoMessage);
            const sanitizedHtml = DOMPurify.sanitize(demoMessage.html);
            setHtml(sanitizedHtml);
            setSender(demoMessage.senderInfo.sender);
            setAddress(demoMessage.senderInfo.address);
            setTitle(demoMessage.subject);
            setDate(demoMessage.creationDate);

            const eventsData = Array.from(events.values());

            setFetchedEvents(eventsData);
            setFetchedEventCount(eventsData.length);
            setFutureEventsCount(countFutureEvents(eventsData));
            return;
        }

        try {
            let messageResponse;
            let mode;

            if (sourceType.startsWith('email')) {
                mode = 'email';
                messageResponse = await fetch(`/api/messages/${sourceId}`, { signal, credentials: 'include' }); // Directly await the fetch request
            } else if (sourceType.startsWith('url')) {
                mode = 'url';
                messageResponse = await fetch(`/api/urls/${sourceId}`, { signal, credentials: 'include' }); // Directly await the fetch request
            }

            setSrcMode(mode);
            const messageData = await messageResponse.json();
            setMessageData(messageData);

            switch (mode) {
                case 'url':
                    setSender(messageData.creatorInfo.sender);
                    setAddress(messageData.creatorInfo.address);
                    setTitle(unescape(messageData.urlTitle));
                    setDate(messageData.parseDate);
                    setUrlPath(messageData.urlPath);
                    break;

                case 'email':
                default:
                    setSender(messageData.senderInfo.sender);
                    setAddress(messageData.senderInfo.address);
                    setTitle(messageData.subject);
                    setDate(messageData.creationDate);
                    break;
            }

            if (messageData.html) {
                const sanitizedHtml = DOMPurify.sanitize(messageData.html);
                setHtml(sanitizedHtml);
            } else if (messageData.text) {
                setHtml(messageData.text);
            }

            const filteredEvents = filterEvents(events, sourceId);

            const sortedEvents = filteredEvents.sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });

            setFetchedEvents(sortedEvents);
            setFetchedEventCount(sortedEvents.length);
            setFutureEventsCount(countFutureEvents(sortedEvents));

        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request aborted:', error.message);
            } else {
                console.error('Error fetching data:', error);
            }
        }
    };

    const filterEvents = (eventsMap, targetSourceContentId) => {
        return Array.from(eventsMap.values()).filter(event =>
            !event.expandedDate && // Exclude expanded events
            event.mentions.some(mention => mention.sourceContentId === targetSourceContentId)
        );
    };

    // useEffect for initial load
    useEffect(() => {
        const controller = new AbortController();
        // Fetch data and initialize drawer
        fetchData(controller);

        return () => {
            controller.abort(); // Abort any ongoing requests
        };
    }, [sourceId]);

    const onClose = () => {
        closeMessage();
    }

    useEffect(() => {
        const hScroller = document.querySelector('.card-content .h-scroller');
        
        if (hScroller && fetchedEvents?.length > 0) {
            let scrollTarget;
            if (eventId) {
                // search by eventId
                scrollTarget = hScroller.querySelector(`.event[data-event-id="${eventId}"]`);
            } else {
                // Find the first upcoming event element
                scrollTarget = hScroller.querySelector('.event.upcoming');
            }
            
            if (scrollTarget) {
                // Scroll to the element's position
                hScroller.scrollTo({
                    left: scrollTarget.offsetLeft - 30,
                    behavior: 'smooth'
                });
            }
        }
    }, [fetchedEvents]); // Run when fetchedEvents updates

    const sourceIsPDFLink = (event) => {
        for (let mention of event.mentions) {
            if (mention.sourceType.includes('pdf')) {
                return true;
            }
        }
        return false;  // Return null if no hyperlink found
    };

    const headerContent = (
        <>
            <div className="message-sender">
                <PersonComponent sender={sender} className='person' />
                {sender}{(srcMode === 'url') && address == userProfile.email ? ' (You)' : ''}
            </div>
            <div className='message-details'>
                <div className="message-subject">
                    <FontAwesomeIcon className="icon" icon={srcMode === 'email' ? faEnvelopeOpenText : faFileCircleCheck} />
                    {title}
                </div>
                <div className="message-date">
                    <FontAwesomeIcon className="icon" icon={faCalendarDay} />
                    {messageData ? moment(date).format("MMM D") : null}
                </div>
            </div>
            <span className="note">{fetchedEventCount} event{fetchedEventCount > 1 ? 's' : ''} {srcMode === 'email' ? ' in this message' : ' found in URL'}</span>
        </>
    )

    const horizontalContent = (
        <>
            {fetchedEvents && fetchedEvents.map((event, index) => (
                <div
                    key={index}
                    className={`event${event.firstView ? ' firstview' : ''}${(event.upcoming || event.demoEvent) ? ' upcoming' : ''}${event.tentative ? ' tentative' : ''}`}
                    data-event-id={event._id}
                    onClick={(e) => { e.preventDefault(); openSourceEvent(event._id); }}>
                    <div className="date">
                        {event.monthEvent ? moment(event.startLocal).format('MMMM') : moment(event.startLocal).format('MMM D')}
                    </div>
                    <div className="title">
                        {event.eventName}
                    </div>
                    <EventTime event={event} />
                    {sourceIsPDFLink(event) && (<FontAwesomeIcon className="pdf-icon" icon={faFilePdf} tags={event.tags} />)}
                </div>
            ))}
        </>
    )

    const verticalContent = (
        <>
            <div className={`${(srcMode === 'url') ? ' dimmed' : ''}`} >
                {(srcMode === 'email' && html) &&
                    <div
                        className="email-content"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                }
                {(srcMode === 'url') &&
                    <div className='url-details'>
                        <p><FontAwesomeIcon className="icon" icon={faCalendarPlus} />{fetchedEventCount} events added by {address == userProfile.email ? ' you' : ' ' + address} {moment(date).fromNow()}</p>
                        <p><FontAwesomeIcon className="icon" icon={faHourglassStart} />{futureEventsCount} of {fetchedEventCount} events are in the future</p>
                        <p><FontAwesomeIcon className="icon" icon={faFileArrowUp} />{urlPath}</p>
                    </div>
                }
            </div>
        </>
    )

    return (
        <CardComponent
            className={`message-card${demoMode ? ' demo-mode':''}`}
            data={fetchedEvents}
            disclosureItems={disclosureItems}
            headerContent={headerContent}
            horizontalContent={horizontalContent}
            verticalContent={verticalContent}
            onClose={onClose}
        />
    );
};

export default MessageComponent;
