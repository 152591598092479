import React, { useEffect, useState, useContext } from 'react';
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';
import { ProfileContext } from '../contexts/ProfileContext';
import WatchlistComponent from '../components/WatchlistComponent';
import ForwardingAddressComponent from '../components/ForwardingAddressComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faPersonThroughWindow, faTrashAlt, faCircleXmark, faPowerOff, faBoltLightning, faCircleChevronLeft, faUserPlus, faCloudSunRain, faSquareArrowUpRight, faAppleAlt, faEnvelopeCircleCheck, faInbox, faHandHolding, faHandshake, faHandshakeSimple, faHatWizard, faLifeRing, faHelicopterSymbol, faCircleInfo, faSuitcaseMedical, faHandshakeAngle, faMobile, faDownload, faBell, faFlag } from '@fortawesome/free-solid-svg-icons'
import { faCopy, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { checkNotificationPermission } from "../services/firebaseService"
import '../styles/Profile.css'

const Profile = () => {
    const { userProfile, updatePreferences, deleteProfileData, deleteProfile } = useContext(ProfileContext);
    const { showPrompt, showToast } = useGlobalPrompts();
    const [advance, setAdvance] = useState(false);
    const [forwardingAddress, setForwardingAddress] = useState(null);
    const [lastLocation, setLastLocation] = useState(null);
    const [events, setEvents] = useState(null);
    const [totalEvents, setTotalEvents] = useState(0);
    const [address, setAddress] = useState('');
    const [gcalConnected, setGcalConnected] = useState(false);
    const [gmailConnected, setGmailConnected] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const isPWA = window.matchMedia('(display-mode: fullscreen)').matches;


    useEffect(() => {
        try {
            // Check permission after initialization
            const checkPermission = async () => {
                const subscribed = await checkNotificationPermission();
                if (subscribed) {
                    setIsSubscribed(true);
                }
            };

            checkPermission();
        } catch (error) {
            console.error('Failed to initialize messaging:', error);
        }
    }, []);


    const handleAdvanceChange = (newAdvance) => {
        setAdvance(newAdvance);
        // If false, do not let user exit profile.
    };

    const fetchEvents = async () => {

        try {
            const eventsResponse = await fetch('/api/events', { credentials: 'include' });

            if (eventsResponse.ok) {
                const eventsData = await eventsResponse.json();

                setLastLocation(userProfile.lastLocation);
                setEvents(eventsData.events);

                if (userProfile.forwardingAddress && userProfile.forwardingAddress.id) {
                    setForwardingAddress(userProfile.forwardingAddress.address);
                }

                if (userProfile.emailProvider) {
                    const isGmailConnected = userProfile.emailProvider == 'gmail';
                    setGmailConnected(isGmailConnected);
                }

                if (userProfile.calendarProvider) {
                    const isGCalConnected = userProfile.calendarProvider == 'gcal';
                    setGcalConnected(isGCalConnected);
                }

                if (userProfile.preferences && userProfile.preferences.calendarInvitee) {
                    setAddress(userProfile.preferences.calendarInvitee);
                }


                if (eventsData.events) {
                    setTotalEvents(eventsData.events.length);
                }

            } else {
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (address.length === 0 || address.split('.').length < 2) {
            return;
        }

        const preferences = {}
        preferences.calendarInvitee = address;

        updatePreferences(preferences, "Invitee address saved");
    };

    const handleClear = () => {
        setAddress('');
    };

    const handleAddressCopy = () => {
        navigator.clipboard.writeText(forwardingAddress).then(() => {
            showToast({
                icon: <FontAwesomeIcon className='icon' icon={faCopy} />,
                title: 'Copied to Clipboard',
                description: forwardingAddress
            });
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }

    useEffect(() => {
        if (!userProfile) {
            return;
        }

        fetchEvents();
        window.scrollTo(0, 0);
    }, [userProfile]);

    const renderedContent = (userProfile !== null && events !== null) && (
        <div className='profile'>
            <div className='back' disabled={!advance} onClick={() => { window.location.href = '/focus' }}><FontAwesomeIcon className='icon' icon={faCircleChevronLeft} /> Back to Schedule</div>

            <h2>{userProfile.displayName}</h2>
            <div className='benefits'>
                You have saved <span className='savings'>{totalEvents}</span> minutes this month <br />using Simple Schedule.
            </div>

            {forwardingAddress &&
                <>
                    <div className='title'><FontAwesomeIcon className='icon' icon={faEnvelopeCircleCheck} />Forwarding Address</div>
                    <div className='settings forward'>
                        <ForwardingAddressComponent
                            forwardingAddress={forwardingAddress}
                            handleAddressCopy={handleAddressCopy}
                        />
                        <div className='note'>
                            Your personal forwarding address accepts emails and creates calendar events for your account. Do not share with others.
                        </div>
                    </div>
                </>
            }

            {gmailConnected &&
                <>
                    <div className='title'><FontAwesomeIcon className='icon' icon={faEnvelope} />Gmail Addresses</div>
                    <WatchlistComponent onAdvanceChange={handleAdvanceChange} />
                </>
            }
            <div className='title'><FontAwesomeIcon className='icon' icon={faBoltLightning} />Syncing</div>
            <div className='settings sync'>
                <button className='gcal' onClick={() => { window.location.href = '/join/calendar' }}>Google Calendar
                    <span className={`status ${gcalConnected ? "connected" : "connect"}`}>
                        {gcalConnected ? 'Connected' : 'Connect'}
                    </span>
                </button>
                {gcalConnected && (
                    <>
                        <div className='title'>
                            <FontAwesomeIcon className='icon' icon={faUserPlus} />Calendar Invitee
                        </div>
                        <form onSubmit={handleSubmit}>
                            <input className="invitee-input" type="email" inputMode="email" autoComplete="email" placeholder="Enter email address" value={address} onChange={(e) => setAddress(e.target.value)} />
                            {address && (
                                <button type="button" className="clear" onClick={handleClear}>
                                    <FontAwesomeIcon className="icon" icon={faCircleXmark} />
                                </button>
                            )}
                            <button className="right-button" type="submit">Save</button>
                        </form>
                    </>
                )}
            </div>
            <div className='note'>
                Connect your calendar and tap the <FontAwesomeIcon className='icon' icon={faBoltLightning} /> button to sync an event from your schedule to your calendar.
            </div>
            {gcalConnected && (
                <div className='note'>
                    Invite someone else to your calendar events by saving an address.
                </div>
            )}
            {isPWA ?
                <>
                    <div className='title'><FontAwesomeIcon className='icon' icon={faFlag} />Notifications</div>
                    <div className="settings">
                        <button
                            className="sources notifications"
                            onClick={() => {
                                window.location.href = '/notifications'
                            }}
                        >
                            <FontAwesomeIcon className="icon" icon={faBell} />
                            Enable Notifications
                        </button>
                    </div>
                    <div className='note'>
                        Turn on notifications to get reminders about upcoming events.
                    </div>
                </>
                :
                <>
                    <div className='title'><FontAwesomeIcon className='icon' icon={faDownload} />Install App</div>
                    <div className="settings">
                        <button
                            className="sources"
                            onClick={() => {
                                window.location.href = '/install'
                            }}
                        >
                            <FontAwesomeIcon className="icon" icon={faMobile} />
                            Add to Home Screen
                        </button>
                    </div>
                    <div className='note'>
                        Access Simple Schedule from an icon on your home screen.
                    </div>
                </>
            }
            <div className='title weather'><img src="/apple-brands-solid.svg" />Weather</div>
            <div className="settings weather">
                <button
                    className="sources"
                    onClick={() => {
                        window.open(
                            'https://developer.apple.com/weatherkit/data-source-attribution/',
                            '_blank', // Opens in a new tab or window
                            'noopener,noreferrer' // Security attributes
                        );
                    }}
                >
                    <FontAwesomeIcon className="icon" icon={faSquareArrowUpRight} />
                    Other Data Sources
                </button>
            </div>
            <div className='note'>
                Forecast for {lastLocation}
            </div>

            <div className='title'><FontAwesomeIcon className='icon' icon={faPowerOff} />Disconnect</div>
            <div className='settings disconnect'>
                <button className='logout' onClick={() => { window.location.href = '/api/logout' }}><FontAwesomeIcon className='icon' icon={faRightFromBracket} /> Log Out</button>
                <button className='delete' onClick={deleteProfileData} ><FontAwesomeIcon className='icon' icon={faTrashAlt} /> Delete Data</button>
                <button className='delete' onClick={deleteProfile} ><FontAwesomeIcon className='icon' icon={faPersonThroughWindow} /> Delete Account</button>
            </div>
            <div className='note'>
                Deleting your data or account cannot be undone. Events saved to your external calendar are not removed.
            </div>
        </div>
    );

    return renderedContent;
};

export default Profile;