import moment from 'moment';

import { useEffect, useState, useContext } from "react";

import { FeedContext } from '../contexts/FeedContext';
import CardComponent from "./CardComponent";
import PersonComponent from './PersonComponent';
import EventIconLabel from './EventIconLabel';
import { getFirstTwoInitials, getColors } from '../utils/FormatUtils';


import '../styles/BoltComponent.css'

const BoltComponent = ({
    onLoad,        // New prop for load callback
    onClose,
    openEvent,
}) => {

    const { events } = useContext(FeedContext);
    const [addedEvents, setAddedEvents] = useState();
    const [unviewedEventCount, setUnviewedEventCount] = useState(0);

    useEffect(() => {
        if (events) {
            const added = Array.from(events.values())
                .filter(event =>
                    // Filter out past events
                    moment(event.startLocal).isSameOrAfter(moment(), 'day') &&
                    !event.expandedDate
                )
                // Sort by createdAt timestamp, most recent first
                .sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
                // Take only the first 20 items
                // .slice(0, 20)
                // Group by relative time
                .reduce((groups, event) => {
                    const timeGroup = moment(event.createdAt).fromNow();
                    if (!groups[timeGroup]) {
                        groups[timeGroup] = [];
                    }
                    groups[timeGroup].push(event);
                    return groups;
                }, {});

            // Sort events within each group by startLocal
            Object.keys(added).forEach(group => {
                added[group].sort((a, b) =>
                    moment(a.startLocal).valueOf() - moment(b.startLocal).valueOf()
                );
            });

            setAddedEvents(added);

            // Count unviewed events
            const unviewedCount = Object.values(added)
                .flat()
                .filter(event => !event.firstView)
                .length;

            setAddedEvents(added);
            setUnviewedEventCount(unviewedCount);
        }
    }, [events]);

    const renderMentioner = (mention) => {
        return (
            <PersonComponent
                key={mention._id}
                sender={mention.sourceSender}
                onClick={(e) => {
                    //e.preventDefault();
                    //openMessage(mention.sourceContentId, mention.sourceType, eventId);
                    //hideDrawer();
                }}
            />
        );
    };

    const headerContent = (
        <>
            <h2>Latest Events
                {unviewedEventCount > 0 &&
                    <div className='circle'>
                        <span className='event-count'>{unviewedEventCount}</span>
                    </div>
                }
            </h2>
        </>
    )
    const verticalContent = (
        <>
            {addedEvents && Object.keys(addedEvents).length > 0 &&
                <div className="new-events">
                    {Object.entries(addedEvents).map(([timeGroup, events]) => (
                        <div key={timeGroup} className="time-group">
                            <div className="event-updated">
                                {timeGroup}
                            </div>
                            {events.map((event, index) => (
                                <div key={event.id || index}
                                    className={`event${event.firstView ? ' firstview' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openEvent(event._id);
                                    }}
                                >
                                    <div className='event-icon'>
                                        <EventIconLabel eventDetails={event.eventName + ' ' + event.description} includeDefaultLabel={true} tags={event.tags} />
                                    </div>
                                    <div className="event-details">
                                        <div className="event-name">{event.eventName}</div>
                                        <div className="event-date">
                                            <div className='day'>{moment(event.startLocal).format('ddd, MMM D')}</div>
                                            <div className='time'>{event.dateTime && moment(event.startLocal).format('h:mm a')}</div>
                                        </div>
                                    </div>
                                    <div className='last-mention'>
                                        {renderMentioner(event.mentions[0])}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            }
        </>
    );

    return (
        <CardComponent
            data={events}
            headerContent={headerContent}
            verticalContent={verticalContent}
            className="bolt-card"
            onClose={onClose}
        />
    );
};

export default BoltComponent;