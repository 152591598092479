import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { FeedContext } from '../contexts/FeedContext';
import { ProfileContext } from '../contexts/ProfileContext';
import FocusFeed from '../components/FocusFeed';
import FocusNavigation from '../components/FocusNavigation';
import MessageComponent from '../components/MessageComponent';
import EventComponent from '../components/EventComponent';
import CheckMessages from '../components/CheckMessages';
import CreateComponent from '../components/CreateComponent';
import BoltComponent from '../components/BoltComponent';


const Focus = ({ tutorial }) => {
  const navigate = useNavigate();
  const { setTutorialFirstView } = useContext(ProfileContext);
  const { setDemoMode, startTimestamp } = useContext(FeedContext);
  const [eventId, setEventId] = useState(null);
  const [messsageEventId, setMessageEventId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [sourceType, setSourceType] = useState(null);
  const [card, setCard] = useState(false);
  const focusRef = useRef(null);
  const navRef = useRef(null);
  const navExpandedRef = useRef(false);
  const feedContainerRef = useRef(null);
  const currentDayRef = useRef(null);
  const attentionMarkerRef = useRef(null);
  const attentionMarkerYRef = useRef();
  const intersectionObserver = useRef(null);
  const swipingActiveRef = useRef(false);

  useEffect(() => {
    if (tutorial) {
      setDemoMode(true);
    }

    return () => {
      // Cleanup function
      if (focusRef.current) {
        focusRef.current.style.transition = 'opacity 0.2s ease-in';
        focusRef.current.style.opacity = 1;
      }
    };
  }, [tutorial]);

  const exitDemo = () => {
    closeEvent();
    closeMessage();

    if (focusRef.current) {
      focusRef.current.style.transition = 'opacity 0.6s ease-out';
      focusRef.current.style.opacity = 0;
    }

    // Clear states before navigation
    setDemoMode(false);
    setTutorialFirstView(true);

    setTimeout(() => {
      navigate('/focus');
    }, 600);
  };

  const openEvent = (id) => {
    console.log('openEvent');
    setEventId(id);
    if (!tutorial) navigate(`/focus/event/${id}`);
  };

  const closeEvent = () => {
    console.log('closeEvent');
    setEventId(null);
    if (!tutorial) navigate(`/focus`);
  };

  const openMessage = (id, type, eId) => {
    setSourceId(id);
    setSourceType(type);
    if (eId) setMessageEventId(eId);
    if (!tutorial) navigate(`/focus/message/${id}`);
  };

  const closeMessage = () => {
    setSourceId(null);
    if (!tutorial) navigate(`/focus`);
  };

  const openCard = () => {
    setCard(true);
  }

  const closeCard = () => {
    setCard(false);
  }

  /* Scroll to "today" */
  const scrollToToday = () => {
    scrollToDay(moment(startTimestamp).format('YYYY-MM-DD'));
  }

  /* Scroll to a specific day. Should be in YYYY-MM-DD format */
  const scrollToDay = (day) => {
    const targetDay = document.querySelector(`[id="${day}"]`);
    if (!targetDay) {
      return;
    }

    const element = targetDay;
    const elementRect = element.getBoundingClientRect();
    const topPosition = elementRect.top + window.scrollY - attentionMarkerYRef.current;

    // Create a function to handle the scroll event
    const handleScroll = () => {
      if (Math.abs(window.scrollY - topPosition) < 1) {
        window.removeEventListener('scroll', handleScroll);
        swipingActiveRef.current = false;
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Scroll to the calculated position
    window.scrollTo({
      top: topPosition,
      behavior: 'smooth'
    });
  };

  const setupFeedIntersectionObserver = () => {
    if (!navRef.current) {
      return;
    }
    const navRect = navRef.current.getBoundingClientRect();
    const topPadding = 10;
    const markerTop = topPadding + navRect.top + navRect.height;
    const markerHeight = 60;
    const markerBottom = window.innerHeight - (markerTop + markerHeight);
    attentionMarkerRef.current.style.top = `${markerTop}px`;
    attentionMarkerRef.current.style.height = `${markerHeight}px`;
    attentionMarkerYRef.current = markerTop;

    const rootMargin = `-${markerTop}px 0px -${markerBottom}px 0px`; // Adjust the bottom value dynamically

    // Clean up the previous observer
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    // Create a new IntersectionObserver with the updated rootMargin
    intersectionObserver.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Get the parent of the current `.day` element
            const parentContainer = entry.target.closest('.day-container');
            if (parentContainer?.id && parentContainer.id !== currentDayRef.current) {
              currentDayRef.current = parentContainer.id;
            }
          }
        });
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: rootMargin,
        threshold: 0, // Trigger as soon as even one pixel is visible within the slice
      }
    );

    // Use a specific selector to target `.day` elements inside `.day-container`
    const dayElements = document.querySelectorAll(".feed-container .day-container .day");
    dayElements.forEach((el) => intersectionObserver.current.observe(el));
  }

  // Helper function: Disable body scroll
  const disableFeedScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable body scroll
    document.body.style.touchAction = 'none';
    document.body.style.pointerEvents = 'none';
  };

  // Helper function: Reset styles for body and container
  const resetFeedScroll = () => {
    document.body.style.overflow = 'auto'; // Re-enable body scroll
    document.body.style.touchAction = 'auto';
    document.body.style.pointerEvents = 'auto';
  };

  const [navIntroComplete, setNavIntroComplete] = useState(false);

  /* Navigation has done initial animation in and is correct size/position */
  const handleNavigationIntro = () => {
    if (!navIntroComplete) setNavIntroComplete(true);
  };

  return (
    <div className="focus" ref={focusRef}>
      {eventId &&
        <EventComponent
          eventId={eventId}
          closeEvent={closeEvent}
          openMessage={openMessage}
          scrollToDay={scrollToDay}
          resetFeedScroll={resetFeedScroll}
          disableFeedScroll={disableFeedScroll}
        />}

      {card &&
        <BoltComponent
          onClose={closeCard}
          openEvent={openEvent} />}

      {sourceId &&
        <MessageComponent
          sourceId={sourceId}
          sourceType={sourceType}
          eventId={messsageEventId}
          closeMessage={closeMessage}
          openEvent={openEvent} />}

      {!tutorial &&
        <FocusNavigation
          navRef={navRef}
          navExpandedRef={navExpandedRef}
          feedContainerRef={feedContainerRef}
          currentDayRef={currentDayRef}
          navIntroComplete={navIntroComplete}
          attentionMarkerRef={attentionMarkerRef}
          swipingActiveRef={swipingActiveRef}
          openCard={openCard}
          setupFeedIntersectionObserver={setupFeedIntersectionObserver}
          scrollToToday={scrollToToday}
          scrollToDay={scrollToDay}
          resetFeedScroll={resetFeedScroll}
          disableFeedScroll={disableFeedScroll}
          handleNavigationIntro={handleNavigationIntro} />}

      {!tutorial &&
        <CheckMessages />}

      {!tutorial &&
        <CreateComponent
          openMessage={openMessage} />}

      <FocusFeed
        feedContainerRef={feedContainerRef}
        setupFeedIntersectionObserver={setupFeedIntersectionObserver}
        attentionMarkerRef={attentionMarkerRef}
        navIntroComplete={navIntroComplete}
        scrollToToday={scrollToToday}
        openEvent={openEvent}
        closeEvent={closeEvent}
        openMessage={openMessage}
        closeMessage={closeMessage}
        exitDemo={exitDemo} />
    </div>
  );
};

export default Focus;
