import React from 'react';
import SuccessIcon from '../assets/SuccessIcon';

const FilterSuccess = () => {
  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h1>Ready</h1></div>
      <div className="onboarding-main">
        <div><SuccessIcon size={120} color="#ff6700" /></div>
        <h3>You completed the filter steps</h3>
      </div>
      <div className="onboarding-continue">
        <button className='continue' onClick={() => { window.location.href = '/focus' }}>Continue</button>
      </div>
    </div>
  );
};

export default FilterSuccess;
