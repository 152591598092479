import React, { createContext, useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import { formatEventDate } from '../utils/FormatDates';
import { getDemoMap, getDemoMessage } from '../utils/Demo';
import { ProfileContext } from './ProfileContext';
import { isToday } from '../utils/FormatDates'
import { getWeatherIcon, getReadableCode, celsiusToFahrenheit, kmToMiles } from '../utils/FormatWeather'

// Create a context
const FeedContext = createContext();

const FeedProvider = React.memo(({ children }) => {
  const { userProfile, setTutorialFirstView } = useContext(ProfileContext);
  /* Loading States */
  const firstRunRef = useRef(false);
  const [ESloadingState, setESLoadingState] = useState(null);

  /* Replace events with demo data - first run tutorial */
  const [demoMode, setDemoMode] = useState(false);
  const demoModeRef = useRef(demoMode);

  const demoEvents = getDemoMap();
  const [demoMessage, setDemoMessage] = useState(null);

  /* Model */
  const [startTimestamp, setStartTimestamp] = useState(Date.now()); // Determines 'today'
  const [events, setEvents] = useState(null); // Updating events updates days
  const [eventCount, setEventCount] = useState(0);
  const [days, setDays] = useState(new Map());
  const [months, setMonths] = useState(new Map());
  const [monthEvents, setMonthEvents] = useState(new Map());
  const [currentWeather, setCurrentWeather] = useState(null);
  const [weatherForecast, setWeatherForecast] = useState(null);

  // SSE source
  const eventSourceRef = useRef(null);

  /* Abort events and profile controllers */
  const abortController = useRef(null);
  const abortWeatherController = useRef(null);


  const init = async () => {
    console.log('FeedContext: init');

    if (!eventSourceRef.current) {
      eventSourceRef.current = new EventSource('/api/checkmessages');
      eventSourceRef.current.addEventListener('open', onopen);
      eventSourceRef.current.addEventListener('message', onmessage);
      eventSourceRef.current.addEventListener('error', onerror);
    }
  }

  const destroy = () => {
    console.log('FeedContext: destroy');
    if (abortController.current) {
      abortController.current.abort();
    }

    if (abortWeatherController.current) {
      abortWeatherController.current.abort();
    }

    if (eventSourceRef.current && eventSourceRef.current.readyState !== EventSource.CLOSED) {
      eventSourceRef.current.removeEventListener('open', onopen);
      eventSourceRef.current.removeEventListener('message', onmessage);
      eventSourceRef.current.removeEventListener('error', onerror);
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
  }

  /* Grabs all events from api */
  const fetchEvents = async () => {
    console.log('FeedContext: fetchEvents');
    if (demoModeRef.current) { // Exit as in demo mode
      console.log('FeedContext: fetchEvents - demoMode: ' + demoModeRef.current)
      return;
    }

    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();

    try {
      // Clear previous data before fetching new data
      setEvents(null);
      setDays(new Map());
      setMonths(new Map());
      setMonthEvents(new Map());
      setEventCount(0); // Set event count to 0

      const eventsResponse = await fetch('/api/events', { signal: abortController.current.signal, credentials: 'include' }); // Pass signal to fetch

      if (eventsResponse.ok) {
        const eventsData = await eventsResponse.json();
        const eventsArray = eventsData.events;
        const eventsMap = new Map(eventsArray.map(event => [event._id, event]));

        setEventsAndDates(eventsMap);

        if (eventsMap.size === 0) {
          console.log('FeedContext: fetchEvents set firstRun true');
          firstRunRef.current = true;
        }

      } else if (eventsResponse.status === 401) {
        window.location.href = '/'; // Redirect to login page

      } else {
        console.error('Error:', eventsResponse.status, eventsResponse.statusText);
      }

    } catch (error) {
      // TODO: This catches and errors from Abort Controller on destroy. 
    }
  }

  const fetchWeather = async () => {
    console.log('FeedContext: fetchWeather');
    if (currentWeather || weatherForecast) {
      console.log('Weather already fetched.')
      return;
    }

    try {
      // Initialize an AbortController for canceling fetch requests if needed
      abortWeatherController.current = new AbortController();
      const { signal } = abortWeatherController.current;

      // Make both requests in parallel using Promise.all
      const [currentWeatherResponse, weatherForecastResponse] = await Promise.all([
        fetch('/api/weather/current', { signal, credentials: 'include' }),
        fetch('/api/weather/forecast', { signal, credentials: 'include' }),
      ]);

      // Check if both responses are successful
      if (!currentWeatherResponse.ok || !weatherForecastResponse.ok) {
        throw new Error(
          `Failed to fetch weather data: Current Weather Status ${currentWeatherResponse.status}, Forecast Status ${weatherForecastResponse.status}`
        );
      }

      // Parse JSON responses in parallel
      const [currentWeatherData, weatherForecastData] = await Promise.all([
        currentWeatherResponse.json(),
        weatherForecastResponse.json(),
      ]);

      setCurrentWeather(currentWeatherData.currentWeather);
      setWeatherForecast(weatherForecastData.forecastDaily);

    } catch (error) {
      // Handle errors (e.g., fetch failures, aborts)
      if (error.name === 'AbortError') {
        console.log('Weather fetch aborted');
      } else {
        console.error('Error fetching weather data:', error.message);
      }
    } finally {
      // Clean up or reset after the fetch
      console.log('Fetch completed');
    }
  };

  const getForecast = (day) => {

    console.log('FeedContext: getForecast');
    if (!currentWeather || !weatherForecast ) {
      console.log('Weather not fetched.')
      return;
    }

    if (isToday(day)) {
      const forecast = {}
      forecast.today = true;
      forecast.tempUnit = 'c'
      forecast.speedUnit = 'km/h'
      if (currentWeather.metadata.units == 'm' && userProfile.preferences.tempUnits == 'f') {
        forecast.temp = celsiusToFahrenheit(currentWeather.temperature) + '°';
        forecast.tempUnit = 'f'
      } else {
        forecast.temp = Math.round(currentWeather.temperature) + '°';
      }
      forecast.symbol = getWeatherIcon(currentWeather.conditionCode);
      forecast.code = getReadableCode(currentWeather.conditionCode);
      forecast.uvIndex = currentWeather.uvIndex;
      forecast.humidity = currentWeather.humidity;
      if (currentWeather.metadata.units == 'm' && userProfile.preferences.distUnits == 'm') {
        forecast.speedUnit = 'mph'
        forecast.windSpeed = kmToMiles(currentWeather.windSpeed);
      } else {
        forecast.windSpeed = Math.round(currentWeather.windSpeed);
      }
      return forecast;
    }

    const tomorrow = moment().add(1, "days"); // Get tomorrow's date
    const tenDaysFromNow = moment().add(10, "days"); // Calculate 10 days from today
    const targetDate = moment(day); // Convert the given date to a Moment object

    // Check if the target date is within the forecast window
    const inForecastWindow = targetDate.isSameOrAfter(tomorrow, "day") && targetDate.isSameOrBefore(tenDaysFromNow, "day");

    if (inForecastWindow && weatherForecast) {
      // Find the forecast for the target day
      const targetForecast = weatherForecast.days.find((forecastDay) => {
        const forecastStart = moment(forecastDay.forecastStart);
        const forecastEnd = moment(forecastDay.forecastEnd);
        return targetDate.isBetween(forecastStart, forecastEnd, "day", "[]"); // Inclusive range
      });

      if (targetForecast) {

        // Build the forecast object
        const forecast = {};
        forecast.tempUnit = 'c'
        forecast.speedUnit = 'km/h'
        if (weatherForecast.metadata.units === 'm' && userProfile.preferences.tempUnits === 'f') {
          forecast.tempUnit = 'f'
          forecast.max = forecast.temp = celsiusToFahrenheit(targetForecast.temperatureMax) + '°';
          forecast.min = celsiusToFahrenheit(targetForecast.temperatureMin) + '°';
        } else {
          forecast.max = forecast.temp = Math.round(targetForecast.temperatureMax) + '°';
          forecast.min = Math.round(targetForecast.temperatureMin) + '°';
        }
        forecast.symbol = getWeatherIcon(targetForecast.conditionCode);
        forecast.code = getReadableCode(targetForecast.conditionCode);
        forecast.uvIndex = targetForecast.maxUvIndex;
        if (weatherForecast.metadata.units == 'm' && userProfile.preferences.distUnits == 'm') {
          forecast.speedUnit = 'mph'
          forecast.windSpeed = kmToMiles(targetForecast.windSpeedMax);
        } else {
          forecast.windSpeed = Math.round(targetForecast.windSpeedMax);
        }

        // Get the weather icon for the condition code
        forecast.symbol = getWeatherIcon(targetForecast.conditionCode);
        forecast.precipitationChance = parseFloat(targetForecast.precipitationChance);

        return forecast; // Return the processed forecast
      } else {
        console.log("No matching forecast day found");
        return null;
      }
    } else {
      return null;
    }

  }

  const updateEvent = (updatedEvent) => {
    console.log('FeedProvider: updateEvent');

    // Clone the current events Map to maintain immutability
    // Events can be a new map. The events themselves should not be cloned as react is using those references in the view
    const newEvents = new Map(events);

    const eventToUpdate = newEvents.get(updatedEvent._id);
    if (!eventToUpdate) { return; } // Exit if event not found

    if (!updatedEvent.range) { // Single event
      // Directly update the properties of the existing event
      Object.assign(eventToUpdate, updatedEvent);

    } else if (updatedEvent.range) { // Range events

      let originalEvent = null;
      let orginalId = null;

      if (!updatedEvent.expandedDate) { // Master event for ranges
        originalEvent = eventToUpdate;
        orginalId = updatedEvent._id;

      } else if (updatedEvent.expandedDate) { // Expanded events for ranges
        originalEvent = newEvents.get(updatedEvent.originalId);
        orginalId = updatedEvent.originalId;
      }

      // Remove properties that should not override the original event
      delete updatedEvent._id;
      delete updatedEvent.expandedDate;
      delete updatedEvent.startLocal;
      delete updatedEvent.endLocal;
      delete updatedEvent.dateTime;
      delete updatedEvent.originalId;

      Object.assign(originalEvent, updatedEvent); // This works to update the original event

      // Update all expanded events that share the same originalId
      newEvents.forEach((expandedEvent, key) => {
        if (expandedEvent.expandedDate && expandedEvent.originalId === orginalId) {
          Object.assign(expandedEvent, updatedEvent);
        }
      });
    }

    // Update the events state with the modified Map
    setEvents(newEvents);
    setDateMaps(newEvents);
  };

  /* Sets events and days, expanding ranges and maintaining a Map structure */
  const setEventsAndDates = (eventsArray) => {
    console.log('FeedContext: setEventsAndDates');

    let expandedEvents = new Map();

    eventsArray.forEach((event) => {
      event.startLocal = formatEventDate(event.start, event.dateTime);

      event.tentative = event.tags.includes('tentative');
      event.cancel = event.tags.includes('cancel');
      event.update = event.tags.includes('update');
      event.reschedule = event.tags.includes('reschedule');

      // Determine the relevant date
      const relevantDate = event.end
        ? formatEventDate(event.end, event.dateTime)
        : event.startLocal;

      // Check if the event is upcoming
      const isFutureDateTime = event.dateTime && moment(relevantDate).isAfter(moment());
      const isFutureDateOnly = !event.dateTime && moment(relevantDate).isSameOrAfter(moment(), 'day');

      event.upcoming = isFutureDateTime || isFutureDateOnly;

      // Add endLocal property if event.end exists
      if (event.end) {
        event.endLocal = relevantDate;
      }

      if (event.range && !event.monthEvent && !event.weekEvent) {
        event.startRangeLocal = formatEventDate(event.startRange, false);
        event.endRangeLocal = formatEventDate(event.endRange, false);

        expandedEvents.set(event._id, event); //set first event in the map

        const weeklyRecurrence = event?.recurrence?.frequency?.toLowerCase() === 'weekly';
        const recurrenceDays = (event?.recurrence?.byDay || []).map((day) => day.toLowerCase());        

        // Expand the event into individual days within the range
        let currentDate = moment(event.startRangeLocal);
        const endDate = moment(event.endRangeLocal);

        currentDate.add(1, 'day');

        while (currentDate.isSameOrBefore(endDate, 'day')) {
          const eventId = `${event._id}-${currentDate.format('YYYY-MM-DD')}`; // Unique ID for each day
          const dayOfWeek = currentDate.format('dd').toLowerCase(); // map to 2-letter day abbreviation

          if (!weeklyRecurrence || (weeklyRecurrence && recurrenceDays.includes(dayOfWeek))) {
            // Check if the expanded date is in the future
            const isFutureDate = moment(currentDate).isSameOrAfter(moment(), 'day');

            expandedEvents.set(eventId, {
              ...event,
              _id: eventId, // Use the unique ID
              expandedDate: true, // Mark as expanded
              startLocal: currentDate.format('YYYY-MM-DD'), // Set individual date
              endLocal: null,
              dateTime: false,
              originalId: event._id, // Store original ID for reference in updates
              upcoming: isFutureDate // Set upcoming based on date comparison
            });
          }

          currentDate.add(1, 'day'); // Move to next day
        }

      } else {
        expandedEvents.set(event._id, event);

      }
    });

    setEvents(expandedEvents);
    setEventCount(expandedEvents.size);
    setDateMaps(expandedEvents);
  };


  /* Creates new maps for days, months, and monthEvents states */
  const setDateMaps = (events) => {
    console.log('FeedContext: setDateMaps');

    if (events && events.size > 0) {
      let eventsToday = false;
      const daysMap = new Map();
      const monthsMap = new Map();
      const monthEventsMap = new Map();

      // Organize events into days and months simultaneously
      events.forEach(function (event, eventId) {
        // Filter out disliked events before processing
        if (event.dislike) {
          return; // Skip disliked events
        }

        const day = moment(event.startLocal).format('YYYY-MM-DD');

        // Check if there are events for today
        if (moment(day).isSame(moment(), 'day')) {
          eventsToday = true;
        }

        // Organize events by day
        if (!daysMap.has(day)) {
          daysMap.set(day, []);
        }
        daysMap.get(day).push(event);
      });

      // Ensure there's an entry for today if no events are found for it
      if (!eventsToday) {
        const today = moment().format('YYYY-MM-DD');
        daysMap.set(today, []);
      }

      // Create a local variable to hold the updated days data
      const updatedDays = new Map();

      // Loop through each day in the new data
      daysMap.forEach((newEvents, day) => {
        const existingEvents = updatedDays.get(day) || [];

        // Use a Set to ensure events are unique based on _id
        const uniqueEvents = new Map();

        // Add existing events to the Map
        existingEvents.forEach(event => uniqueEvents.set(event._id, event));

        // Add new events to the Map
        newEvents.forEach(event => uniqueEvents.set(event._id, event));

        // Update the day with unique events as an array
        const filteredEvents = Array.from(uniqueEvents.values());

        // Only update if there are events for the day
        if (filteredEvents.length > 0 || moment(day).isSame(moment(), 'day')) {
          updatedDays.set(day, filteredEvents);
        } else {
          updatedDays.delete(day);
        }
      });

      // Sort the events of the day by start timestamp
      updatedDays.forEach((eventsList, day) => {

        eventsList.sort((a, b) => {
          if (a.dateTime && !b.dateTime) {
            return -1;
          } else if (!a.dateTime && b.dateTime) {
            return 1;
          } else {
            return moment(a.startLocal).valueOf() - moment(b.startLocal).valueOf();
          }
        });
      });

      // Sort the updatedDays map and save to a local variable
      const sortedDays = new Map([...updatedDays.entries()].sort());
      setDays(sortedDays);

      // Use sortedDays to create monthsMap and monthEventsMap
      sortedDays.forEach((events, day) => {
        const monthKey = moment(day).format('YYYY-MM'); // Group by month

        // Ensure the month exists in the maps
        if (!monthsMap.has(monthKey)) {
          monthsMap.set(monthKey, []);
        }

        // Process events for the current day
        if (moment(day).isSame(moment(), 'day') && !eventsToday) {
          const monthDays = monthsMap.get(monthKey) || [];
          monthDays.push({ day, events: [] });
          monthsMap.set(monthKey, monthDays);
        }

        events.forEach((event) => {
          if (event.monthEvent) {
            // Only add to monthEventsMap if that month has month events.
            if (!monthEventsMap.has(monthKey)) {
              monthEventsMap.set(monthKey, []);
            }
            monthEventsMap.get(monthKey).push(event);

            // **Edge Condition Fix**: Ensure the first of the month exists in monthsMap
            const firstOfMonth = moment(monthKey).format('YYYY-MM-DD');
            const monthDays = monthsMap.get(monthKey) || [];
            const firstDayEntry = monthDays.find((entry) => entry.day === firstOfMonth);

            if (!firstDayEntry && moment(day).isSame(moment(), 'day')) {
              monthDays.push({ day: firstOfMonth, events: [] });
              monthsMap.set(monthKey, monthDays);
            }

          } else {
            // Add to monthsMap
            const monthDays = monthsMap.get(monthKey) || [];

            // Check if the day already exists in monthsMap
            const dayEntry = monthDays.find((entry) => entry.day === day);

            if (dayEntry) {
              // Add the event to the existing day's events array
              dayEntry.events.push(event);
            } else {
              // Create a new day entry with the event
              monthDays.push({ day, events: [event] });
            }

            // Update monthsMap with the modified days array
            monthsMap.set(monthKey, monthDays);
          }
        });
      });


      // Set the updated maps
      setMonths(monthsMap);
      setMonthEvents(monthEventsMap);
    }
  };


  const onopen = () => {
    setESLoadingState('loading'); // Indicate that SSE connection is open
  };

  const onmessage = (event) => {
    console.log('FeedContext: onmessage');

    const data = JSON.parse(event.data);

    if (firstRunRef.current && data.currentProgress > 0 && data.eventsCount > 0) { // First run, so show first set of messages found
      firstRunRef.current = false;
      console.log('FeedContext: onmessage - firstrun, fetchEvents');
      fetchEvents(); // Grab EVENTS on first set of events parsed.
    }

    if (data.currentProgress < data.totalProgress) { // Messages
      setESLoadingState('progress');

    } else if (data.currentProgress === data.totalProgress) { // all messages checked
      eventSourceRef.current.close();
      setESLoadingState('complete');

      if (data.eventsCount > 0) {
        console.log('FeedContext: onmessage - complete, fetch events');
        fetchEvents(); // Grab EVENTS after all events have been parsed.
      }
    }
  };

  const onerror = () => {
    if (eventSourceRef.current.readyState === EventSource.CLOSED) {
      setESLoadingState('closed');
      eventSourceRef.current.close();
    } else if (eventSourceRef.current.readyState === EventSource.CONNECTING) {
      setESLoadingState('loading');
    } else {
      setESLoadingState('error');
    }
  };

  const demoModeOn = () => {
    console.log('FeedContext: demoMode on');
    setDemoMessage(getDemoMessage(userProfile.displayName));
    setEvents(demoEvents); // Set events manually
    setDateMaps(demoEvents); // Normally days is set after fetching events
    setEventCount(demoEvents.size);
  }

  const demoModeOff = () => {
    console.log('FeedContext: demoMode off ');
    if (!userProfile.preferences || !userProfile.preferences.firstViewTutorial) {
      setTutorialFirstView(true);
    }
  }

  /* Initialize component */
  useEffect(() => { // Check Messages and fetch events when component mounts.
    init();
    // Cleanup function
    return () => {
      destroy();
    };
  }, []);


  /* Keep demo mode as a reference and updated */
  useEffect(() => {
    demoModeRef.current = demoMode; // Keep the ref updated
  }, [demoMode]);

  /* Use effect is called at least once on startup */
  useEffect(() => {
    if (userProfile) {
      // Handle demo mode
      if (demoMode) {
        demoModeOn();
      } else {
        demoModeOff();
      }

      // Fetch events
      fetchEvents();
      fetchWeather();
    }
  }, [userProfile, demoMode]);


  return (
    <FeedContext.Provider value={{
      startTimestamp,
      events,
      days,
      months,
      monthEvents,
      eventCount,
      eventSourceRef,
      ESloadingState,
      demoMode,
      demoMessage,
      currentWeather,
      weatherForecast,
      getForecast,
      fetchEvents,
      updateEvent,
      setDemoMode,
    }}>
      {children}
    </FeedContext.Provider>
  );
});

export { FeedProvider, FeedContext };
