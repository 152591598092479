import React, { useMemo, useState, useEffect, useContext, useRef, useCallback } from 'react';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faCalendarDays } from '@fortawesome/free-regular-svg-icons'
import { faArrowCircleLeft, faArrowDown, faArrowUp, faCheck, faChevronRight, faCow, faDog, faHatCowboy, faKiwiBird, faWorm } from '@fortawesome/free-solid-svg-icons'

import EventIconLabel from './EventIconLabel';
import { ProfileContext } from '../contexts/ProfileContext';
import { FeedContext } from '../contexts/FeedContext';
import { formatMonth, isThisMonth, isToday, formatTimeRange } from '../utils/FormatDates'
import 'weather-icons/css/weather-icons.css';

const FocusFeed = ({ setupFeedIntersectionObserver, feedContainerRef, attentionMarkerRef, navIntroComplete, scrollToToday, openEvent, openMessage, exitDemo }) => {
  const { userProfile } = useContext(ProfileContext);
  const { startTimestamp, days, months, events, monthEvents, currentWeather, weatherForecast, getForecast, demoMode } = useContext(FeedContext);
  const demoComplete = useRef(null);
  const eventCoachmark = useRef(null);

  // Observers 
  const mutationObserver = useRef(null);

  const [pointerIcon, setPointerIcon] = useState(null)

  useEffect(() => {
    /* Navigation has done initial animation in and is correct size/position */
    console.log('useEffect: navIntroComplete');

    if (attentionMarkerRef.current) {
      attentionMarkerRef.current.classList.add('show');
    }
    setupFeedIntersectionObserver();

  }, [navIntroComplete]);

  const prevEventsSizeRef = useRef(0);
  useEffect(() => {
      const currentSize = events?.size || 0;
      if (currentSize !== prevEventsSizeRef.current) {
          scrollToToday();
          prevEventsSizeRef.current = currentSize;
      }
  }, [events]);

  useEffect(() => {
    init();
    return () => {
      destroy();
    };
  }, []);

  const init = () => {
    console.log('FocusFeed: init');
    // Add resize listener
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    mutationObserver.current = new MutationObserver(() => {
      setupFeedIntersectionObserver();
    });

    if (feedContainerRef.current) {
      mutationObserver.current.observe(feedContainerRef.current, { childList: true, subtree: true });
    }
  }

  const handleScroll = () => {
    const today = moment(startTimestamp).format('YYYY-MM-DD');
    const targetDay = document.querySelector(`[id="${today}"]`);

    if (targetDay) {
      const rect = targetDay.getBoundingClientRect();
      if (rect.top < 0) {
        setPointerIcon(faArrowUp);
      } else if (rect.bottom > window.innerHeight) {
        setPointerIcon(faArrowDown);
      } else {
        setPointerIcon(null);
      }
    }
  }

  const destroy = () => {
    console.log('FocusFeed: destroy');
    if (mutationObserver.current) {
      mutationObserver.current.disconnect();
    }
    window.removeEventListener('resize', handleResize);
    window.removeEventListener('scroll', handleScroll);
  }

  const handleResize = () => {
    console.log('FocusFeed: handleResize')
    setupFeedIntersectionObserver();
  };

  const showDemoComplete = () => {
    if (demoMode) {
      demoComplete.current.classList.add('show');
    }
  }

  const dayContainer = useCallback((day, events) => {

    const today = moment(); // Get today's date
    const tenDaysFromNow = moment().add(11, "days"); // Calculate 10 days from today
    const targetDate = moment(day); // Convert the given date to a Moment object

    // Check if the target date is within the forecast window
    const inForecastWindow = targetDate.isSameOrAfter(today, "day") && targetDate.isSameOrBefore(tenDaysFromNow, "day");
    const weather = inForecastWindow ? getForecast(day) : false;

    return (
      <div className="day-container" id={moment(day).format('YYYY-MM-DD')}>
        <div
          className={`day ${isToday(day)}`}
        >
          <div className='ddd'>{moment(day).format('ddd')}</div>
          <div className='MMDD'>
            <span className='DD'>{moment(day).format('D')}</span>
          </div>
          {weather && (
            <div className="weather">
              <div className={`symbol wi ${weather.symbol}`}>
              </div>
              <div className="temp">
                {weather.temp}
              </div>
            </div>
          )}
        </div>
        <div className="event-container">
          {events.length > 0 ? (
            events.map((event, index) => (
              <div
                className={`event${event.dislike ? ' dislike' : ''}${(event.upcoming || event.demoEvent) ? ' upcoming' : ''}${event.firstView ? ' firstview' : ''}${event.tentative ? ' tentative' : ''}`}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  showDemoComplete();
                  openEvent(event._id);
                }}>
                <div className="timing">
                  {formatTimeRange(event)}
                </div>
                <div className="title">
                  {event.eventName}
                </div>
                <EventIconLabel firstView={event.firstView} eventDetails={event.like ? 'like' : event.eventName + ' ' + event.description} includeLabel={false} tags={event.tags} />
                {demoMode && (
                  <div className="coachmark" ref={eventCoachmark}>
                    <FontAwesomeIcon className="icon left" icon={faArrowCircleLeft} />
                    <div className="instruction">
                      Tap event for details
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="event none">
              <div className="timing" >&nbsp;</div>
              <div className="title">
                No Events <br />Today
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }, [demoMode, currentWeather, weatherForecast]);

  const renderMonthEvents = (monthKey) => {
    return (
      <div className='month-events-container'>
        <div className='month'>
          <FontAwesomeIcon className="icon" icon={faCalendarDays} />
        </div>
        <div className="event-container">
          {monthEvents.get(monthKey)?.map((event, index) => (
            <div
              className={`event${event.firstView ? ' firstview' : ''} ${event.upcoming ? ' upcoming' : ''}`}
              key={event._id}
              onClick={(e) => {
                e.preventDefault();
                showDemoComplete();
                openEvent(event._id);
              }}
            >
              <div className="title">
                {event.eventName}
              </div>
              <EventIconLabel
                firstView={event.firstView}
                eventDetails={event.like ? 'like' : event.eventName  + ' ' + event.description}
                includeLabel={false}
                tags={event.tags}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDaysByMonth = useCallback(() => {
    return Array.from(months.entries()).map(([monthKey, monthData]) => (
      <div className="month-container" data-month={monthKey} key={monthKey}>
        <div className={isThisMonth(monthKey, startTimestamp) ? 'month-title today' : 'month-title'}>
          {formatMonth(monthKey)}
        </div>
        {monthEvents?.get(monthKey) && renderMonthEvents(monthKey)}
        {monthData.map(({ day, events }) => (
          <React.Fragment key={day}>
            {dayContainer(day, events)}
          </React.Fragment>
        ))}
      </div>
    ));
  }, [months]);


  const renderPointer = useCallback(() => {
    return (
      (pointerIcon && <div className={`today-pointer${pointerIcon == faArrowUp ? ' up' : ''}${pointerIcon == faArrowDown ? ' down' : ''}`} onClick={() => { scrollToToday(); }}><FontAwesomeIcon className='icon' icon={pointerIcon} /><span className='label'>Today</span></div>)
    )
  }, [pointerIcon])

  return (
    <>
      {useMemo(() => (
        <>
          {demoMode && (
            <div className="demo-mode">
              <div className="demo-title">Tutorial</div>
              <div className="demo-skip" onClick={() => exitDemo()}>
                Skip <FontAwesomeIcon className="icon" icon={faChevronRight} />
              </div>
              <div className="demo-complete" ref={demoComplete}>
                <h2>You Got It!</h2>
                <div className="description">Now it's time for your schedule</div>
                <button className="continue schedule-button" onClick={exitDemo}>
                  <FontAwesomeIcon className="icon" icon={faCalendarCheck} />
                  Continue
                </button>
              </div>
            </div>
          )}

          <div
            ref={feedContainerRef}
            className={`feed-container${demoMode ? ' demo' : ''}`}
          >
            {(months && months.size > 0) &&
              renderDaysByMonth()}
          </div>

          <div ref={attentionMarkerRef} className="attention-marker" />
        </>
      ), [demoMode, days])}

      {!demoMode && renderPointer()}
      <div className="notice">Check important dates. AI can make mistakes.</div>
    </>
  );
}

export default FocusFeed;
