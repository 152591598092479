const weatherIcons = {
  clear: 'wi-day-sunny',
  mostlyclear: 'wi-day-sunny-overcast',
  partlycloudy: 'wi-day-cloudy',
  mostlycloudy: 'wi-day-cloudy',
  cloudy: 'wi-cloudy',
  rain: 'wi-rain',
  heavyrain: 'wi-rain',
  thunderstorm: 'wi-thunderstorm',
  snow: 'wi-snow',
  fog: 'wi-fog',
  windy: 'wi-windy',
  sleet: 'wi-sleet',
  hail: 'wi-hail',
  tornado: 'wi-tornado',
  dust: 'wi-dust',
  smoke: 'wi-smoke',
  drizzle: 'wi-sprinkle',
  showers: 'wi-showers',
  overcast: 'wi-cloudy',
  mist: 'wi-fog',
  sand: 'wi-sandstorm',
  haze: 'wi-day-haze',
  freezingrain: 'wi-rain-mix',
  lightningshowers: 'wi-storm-showers',
};

function getWeatherIcon(conditionCode) {
  const normalizedCode = conditionCode.toLowerCase(); // Normalize the condition code
  const icon = weatherIcons[normalizedCode] || 'wi-na';
  if (icon == 'wi-na') console.log('No icon for: ' + conditionCode);
  return icon;  // Default to 'Not Available' icon
}

function getReadableCode(conditionCode) {
  // Add space before uppercase letters and convert to lowercase
  const readable = conditionCode.replace(/([A-Z])/g, ' $1');
  // Capitalize first letter of final string
  return readable.charAt(0).toUpperCase() + readable.slice(1);
}

function celsiusToFahrenheit (celsius) {
  return Math.round((celsius * 9) / 5 + 32);
}

function kmToMiles(km) {
  return Number((km * 0.621371).toFixed(1));
}

export { getWeatherIcon, getReadableCode, celsiusToFahrenheit, kmToMiles }

