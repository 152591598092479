import React, { useEffect, useState, useContext } from "react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { requestNotificationPermission, checkNotificationPermission, getServiceToken } from "../services/firebaseService"
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';


const Notifications = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { showPrompt } = useGlobalPrompts();

  useEffect(() => {
    console.log('useEffect: Notifications')
    try {
      // Check permission after initialization
      const checkPermission = async () => {
        const subscribed = await checkNotificationPermission();
        console.log('subscribed: ' + subscribed)
        if (subscribed) {
          setIsSubscribed(true);
        }
      };

      checkPermission();
    } catch (error) {
      console.error('Failed to initialize messaging:', error);
    }
  }, []);

  const handleEnableNotifications = async () => {
    const token = await requestNotificationPermission();
    if (token) {
      setIsSubscribed(true);
    } else {
      alert("Notifications permission denied.");
    }
  };

  const debug = async () => {
    const token = await getServiceToken();

    showPrompt({
      title: 'Push Token',
      description: token,
      onConfirm: () => {
        navigator.clipboard.writeText(token);
      },
      confirmMessage: 'Copy',
      cancelMessage: 'Close',
    });
  }

  return (
    <div className="onboarding">
      <div className="notifications onboarding-main">
        <h2>Enable Notifications</h2>
        <button onClick={handleEnableNotifications} disabled={isSubscribed}>
          <FontAwesomeIcon icon={faBell} /> {isSubscribed ? "Enabled" : "Turn On"}
        </button>
        <button onClick={debug} className="cancel note">Debug</button>
      </div>
      <div className="onboarding-continue">
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default Notifications;