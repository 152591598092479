import React, { createContext, useState, useEffect, useRef } from 'react';
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faHeartCrack } from '@fortawesome/free-solid-svg-icons';

// Create a context
const ProfileContext = createContext();

const ProfileProvider = React.memo(({ children }) => {
    const { showPrompt, showToast } = useGlobalPrompts();
    const abortProfileController = useRef(null);
    const [userProfile, setUserProfile] = useState(null);

    const refreshProfile = async () => {
        await fetchProfile();
    }

    /* Grabs the user profile data and sets userProfile */
    const fetchProfile = async () => {
        console.log('ProfileContext: fetchProfileData');

        abortProfileController.current = new AbortController();

        try {
            // Fetch profile data
            const profileResponse = await fetch('/api/user/profile', { signal: abortProfileController.current.signal, credentials: 'include' });

            if (profileResponse.ok) {
                const profileData = await profileResponse.json();

                if (profileData.user) {
                    console.log('ProfileContext: setUserProfile');
                    setUserProfile(profileData.user);
                }
            } else if (profileResponse.status === 401) {
                showPrompt({
                    icon: <FontAwesomeIcon icon={faHeartCrack} />,
                    title: 'Session Expired',
                    description: <span>It's been a while since you logged in. You need to log in again.</span>,
                    onConfirm: () => { window.location.href = '/join' },
                    confirmMessage: 'Reconnect',
                });
                window.location.href = '/join'; // Redirect to login page
            } else {
                console.error('Failed to fetch profile data');
            }

        } catch (error) {
            console.log('Error fetching profile data or geocode:', error.message);
        }
    };

    const setTutorialFirstView = async (viewed) => {

        const preferences = {}
        preferences.firstViewTutorial = viewed;

        try {
            const response = await fetch('/api/user/preferences', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ preferences }),
            });

            if (response.ok) {
                console.log('User Preferences: first view tutorial updated successfully');
            } else {
                console.error('Failed to update user preferences');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const saveUserTimezone = async () => {
        try {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Fetch request with the provided AbortController signal
            const response = await fetch('/api/timezone', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ timezone })
            });

            if (response.ok) {
                console.log('Timezone saved successfully');
            } else {
                console.error('Failed to save timezone');
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Timezone fetch aborted');
            } else {
                console.error('Error saving timezone:', error);
            }
        }
    };

    const updatePreferences = async (preferences, successMessage) => {

        try {
            const response = await fetch('/api/user/preferences', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ preferences }),
            });

            if (response.ok && successMessage) {
                showToast({
                    icon: <FontAwesomeIcon icon={faCircleCheck} />,
                    title: 'Success',
                    description: successMessage,
                });
            } else {
                console.error('Failed to update user preferences');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const deleteProfile = () => {
        const userConfirmed = window.confirm('Are you sure you want to delete your account?');

        if (userConfirmed) {
            fetch('/api/user/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.ok) {
                        window.location.href = '/';
                    } else {
                        // Handle errors here
                        console.error('Account deletion failed');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const deleteProfileData = () => {
        const userConfirmed = window.confirm('Are you sure you want to delete your data?');

        if (userConfirmed) {
            fetch('/api/data/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.ok) {
                        window.confirm('All your data has been deleted.');
                    } else {
                        // Handle errors here
                        console.error('Data deletion failed');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    useEffect(() => {
        if (!userProfile) {
            return;
        }

        if (!userProfile.timezone) {
            // set the user's timezone only if it's not already set.
            saveUserTimezone();
        }
    },[userProfile])

    // Initialize
    useEffect(() => {
        fetchProfile();

        // Cleanup function
        return () => {
            if (abortProfileController.current) {
                abortProfileController.current.abort();
            }
        };
    }, []);

    return (
        <ProfileContext.Provider value={{
            userProfile,
            refreshProfile,
            updatePreferences,
            saveUserTimezone,
            setTutorialFirstView,
            deleteProfileData,
            deleteProfile
        }}>
            {children}
        </ProfileContext.Provider>
    );
});

export { ProfileProvider, ProfileContext };