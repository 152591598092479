import React, { useRef, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCircleXmark, faMagicWandSparkles, faWandMagicSparkles, faSpinner, faWandMagic, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import SuccessIcon from '../assets/SuccessIcon';
import '../styles/CreateComponent.css';
import { FeedContext } from '../contexts/FeedContext';

const CreateComponent = ({ openMessage }) => {
    const { fetchEvents } = useContext(FeedContext);

    const DEFAULT_NOTE = "Grab events from any webpage or PDF";
    const LOADING_NOTE = "Finding events. This will take a few seconds...";

    const containerRef = useRef();
    const overlayRef = useRef();
    const formRef = useRef();
    const inputRef = useRef();
    const progressRef = useRef();
    const hintRef = useRef();
    const [url, setUrl] = useState('');
    const [fetching, setFetching] = useState(false);
    const [hint, setHint] = useState(null);
    const [note, setNote] = useState(DEFAULT_NOTE);
    const [success, setSuccess] = useState(false);
    const [urlId, setUrlId] = useState(null);
    const [urlSourceType, setUrlSourceType] = useState('url');
    const [eventsCount, setEventsCount] = useState(0);

    const clickCreate = async () => {
        containerRef.current.classList.toggle('expand');

        if (containerRef.current.classList.contains('expand') && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('handleSubmit');

        const invalidRegEx = /[^A-Za-z0-9\-._~:/?#\[\]@!$&'()*+,;=%]/;

        let hintMessage;

        if (url.length === 0) {
            hintMessage = "You must type or paste in a URL";
        } else if (!url.includes(".")) {
            hintMessage = "URL is missing a domain name (e.g., .com, .org, .me)";
        } else if (invalidRegEx.test(url)) {
            hintMessage = "URL contains invalid characters";
        } else {
            hintMessage = null;
        }

        if (hintMessage) {

            setHint(hintMessage);
            hintRef.current.classList.add('show');
            inputRef.current.classList.add('incomplete');

            setTimeout(() => {
                hintRef.current.classList.remove('show');
                inputRef.current.classList.remove('incomplete');
            }, 2500);

            return;
        }

        requestUrlSubmission();
    }

    const handleClear = () => {
        console.log('handleClear')
        setUrl('');
    }

    const handleSuccessContinue = () => {
        containerRef.current.classList.remove('expand');
        setNote(DEFAULT_NOTE);
        setUrl('');
        setSuccess(false);
        setFetching(false);

        console.log(urlId, urlSourceType, eventsCount);

        if (eventsCount > 0) {
            openMessage(urlId, urlSourceType);
        }
    }

    const clearOverlay = (e) => {
        // Only clear if the exact element clicked is the overlay (not a child element)
        if (e.target === overlayRef.current) {
            containerRef.current.classList.remove('expand');
            if (!fetching) {
                handleClear();
            }
        }
    };

    const requestUrlSubmission = async () => {
        if (fetching) {
            return;
        }

        try {
            setFetching(true);
            setNote(LOADING_NOTE);

            const response = await fetch(`/api/checkurl?url=${encodeURIComponent(url)}`, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                const responseJson = await response.json(); // Parse the JSON response
                const data = responseJson.data;
                console.log('URL submitted successfully');

                await fetchEvents();

                setUrlId(data.id);
                setEventsCount(data.eventsCount);

                if (data.eventsCount > 0) {
                    setNote(`Great! We found ${data.eventsCount} new events`);
                } else {
                    setNote("No events found. Try a different URL.");
                }

                if (!containerRef.current.classList.contains('expand')) {
                    containerRef.current.classList.add('expand');
                }

                setSuccess(true);
            } else {
                console.error('Failed to get events from URL');
                // Handle error response
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle exception
        } finally {
            setFetching(false);
        }
    }

    return (
        <div className="create-container" ref={containerRef} >
            <div className='overlay' ref={overlayRef} onClick={clearOverlay}>
                {!success && (
                    <>
                        <div className={`progress-animation${fetching ? ' fetching' : ''}`} ref={progressRef}>
                            <FontAwesomeIcon className="icon wand" icon={faWandMagic} />
                            <FontAwesomeIcon className="icon sparkles" icon={faWandMagicSparkles} />
                        </div>

                        <div className={`create-form${fetching ? ' fetching' : ''} ${success ? ' success' : ''}`} ref={formRef}>
                            <div ref={hintRef} className='hint'>&nbsp;{hint}&nbsp;</div>
                            <form onSubmit={handleSubmit}>
                                <input ref={inputRef} className="url-input" disabled={fetching} type="url" inputMode="url" autoComplete="url" placeholder="Enter URL Here" value={url} onChange={(e) => setUrl(e.target.value)} />
                                {url && (
                                    <button type="button" className="clear" onClick={handleClear}>
                                        <FontAwesomeIcon className="icon" icon={faCircleXmark} />
                                    </button>
                                )}
                                <button className="right-button" type="submit" disabled={fetching}><FontAwesomeIcon className="icon" icon={fetching ? faSpinner : faWandMagicSparkles} /></button>
                            </form>
                            <div className='note'>{note}</div>
                        </div>
                    </>
                )}
                {success && (
                    <>
                        <div className="success-animation">
                            <SuccessIcon size={120} color="#ff6700" />
                        </div>

                        <div className="results-continue">
                            <button className='continue' onClick={handleSuccessContinue}>Continue</button>
                            <div className='note'>{note}</div>
                        </div>
                    </>
                )}
            </div>

            <div className="create-controls">
                <button className='create' onClick={clickCreate}>
                    <FontAwesomeIcon className={fetching ? "fetching icon": "icon"} icon={faPlus} />
                </button>
            </div>
        </div>
    );
};

export default CreateComponent;