import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import DisclosureComponent from './DisclosureComponent';
import '../styles/CardComponent.css'

const CardComponent = ({
    data,
    disclosureItems,
    headerContent,      // New prop for header content
    horizontalContent, // New prop for horizontal scrolling content
    verticalContent,   // New prop for vertical scrolling content
    onLoad,        // New prop for load callback
    onClose,       // New prop for close callback
    className         // New prop for custom styling
}) => {

    const [cardLoaded, setCardLoaded] = useState(false);
    const [disclosureOpen, setDisclosureOpen] = useState(null);
    const cardRef = useRef(null);
    const hScrollingRef = useRef(null)
    const vScrollingRef = useRef(null);
    const shadowRef = useRef(null);
    const isDraggingRef = useRef(false);
    const startYRef = useRef(0);
    const currentYRef = useRef(0);
    const cardHeight = useRef(0);

    const drawerStopPosition = '4px';

    const closeCard = () => {
        cardRef.current.style.bottom = '-100%'; // Hide drawer
        shadowRef.current.style.opacity = 0;
        setTimeout(() => {
            if (onClose) onClose(); // Close after transition is complete
        }, 500);
    }

    const toggleDisclosure = (openState) => setDisclosureOpen(openState);

    // Drawer handling logic
    const initializeDrawer = () => {

        const drawer = cardRef.current;
        cardHeight.current = parseFloat(getComputedStyle(cardRef.current).height);

        const handleTouchStart = (e) => {
            console.log('handleTouchStart')
            drawer.style.animation = 'none'; // Clear animation when starting the drag
            drawer.style.transition = 'none'; // Remove the transition to avoid interference with other animations or drags
            startYRef.current = currentYRef.current = e.touches[0].clientY; // Use ref to store startY
            isDraggingRef.current = true;
        };

        const handleTouchMove = (e) => {
            if (isDraggingRef.current) {
                console.log('handleTouchMove')
                e.preventDefault();

                const diffY = e.touches[0].clientY - currentYRef.current;

                const drawerBottom = parseFloat(getComputedStyle(cardRef.current).bottom);
                const setY = drawerBottom - diffY;

                if (setY <= 0) {
                    drawer.style.bottom = `${setY}px`;
                    currentYRef.current = e.touches[0].clientY;
                } else {
                    drawer.style.bottom = drawerStopPosition;
                }

                const opacity = (cardHeight.current + drawerBottom) / cardHeight.current;
                if (shadowRef.current) {
                    shadowRef.current.style.opacity = opacity;
                }
            }
        };

        const handleTouchEnd = () => {
            console.log('handleTouchEnd')
            isDraggingRef.current = false;
            const drawerBottom = Math.abs(parseFloat(getComputedStyle(drawer).bottom));

            // Add a smooth transition for the bottom property
            drawer.style.transition = 'bottom 0.15s ease-in-out';

            if (drawerBottom > drawer.getBoundingClientRect().height / 4) {
                closeCard();
            } else {
                drawer.style.bottom = drawerStopPosition; // Reset position
            }
        };

        const handleScrollTouchStart = (e) => {
            console.log('handleScrollTouchStart')
            startYRef.current = e.touches[0].clientY;
        };

        const handleScrollTouchMove = (e) => {
            console.log('handleScrollTouchStart')
            const scrollableArea = vScrollingRef.current;
            const deltaY = startYRef.current - e.touches[0].clientY; // Compare with last position

            if (scrollableArea) {
                if (scrollableArea.scrollTop === 0) {
                    if (deltaY > 0) { // Moving up - allow content scroll
                        e.stopPropagation();

                    } else if (deltaY < 0) { // Moving down - maybe start drawer drag
                        if (!isDraggingRef.current) {
                            handleTouchStart(e);
                        }
                        handleTouchMove(e);

                    }

                } else { // Not at top - normal scroll
                    e.stopPropagation();
                }
            }
        };

        const handleHScrollerTouch = (e) => {
            const scrollableArea = hScrollingRef.current;
            if (scrollableArea) {
                const isScrollable = scrollableArea.scrollWidth > scrollableArea.clientWidth;
                
                if (isScrollable) {
                    // Content is scrollable - handle horizontal scroll
                    e.stopPropagation();
                } else {
                    // Check if target is an event element or child of event
                    const isEventElement = e.target.classList.contains('event') || 
                                         e.target.closest('.event') ||
                                         // Check if it's a direct child of an event element
                                         (e.target.parentElement?.classList.contains('event'));
                    
                    if (isEventElement) {
                        // Allow event interaction
                        return;
                    } else {
                        // Not interactive - allow drawer drag
                        if (!isDraggingRef.current) {
                            handleTouchStart(e);
                        }
                        handleTouchMove(e);
                    }
                }
            }
        };

        // Attach event listeners
        drawer.addEventListener('touchstart', handleTouchStart);
        drawer.addEventListener('touchmove', handleTouchMove);
        drawer.addEventListener('touchend', handleTouchEnd);

        if (vScrollingRef.current) {
            vScrollingRef.current.addEventListener('touchstart', handleScrollTouchStart);
            vScrollingRef.current.addEventListener('touchmove', handleScrollTouchMove);
        }

        if (hScrollingRef.current) {
            hScrollingRef.current.addEventListener('touchstart', handleHScrollerTouch, { passive: false });
            hScrollingRef.current.addEventListener('touchmove', handleHScrollerTouch, { passive: false });
        }

        setCardLoaded(true);

        return () => {
            drawer.removeEventListener('touchstart', handleTouchStart);
            drawer.removeEventListener('touchmove', handleTouchMove);
            drawer.removeEventListener('touchend', handleTouchEnd);

            if (vScrollingRef.current) {
                vScrollingRef.current.removeEventListener('touchstart', handleScrollTouchStart);
                vScrollingRef.current.removeEventListener('touchmove', handleScrollTouchMove);
            }

            if (hScrollingRef.current) {
                hScrollingRef.current.removeEventListener('touchstart', handleHScrollerTouch, { passive: false });
                hScrollingRef.current.removeEventListener('touchmove', handleHScrollerTouch, { passive: false });
            }
        };
    };

    // useEffect for initial load
    useEffect(() => {
        const cleanupDrawer = initializeDrawer();
        document.body.style.overflow = 'hidden'; // Disable body scroll

        return () => {
            document.body.style.overflow = 'auto'; // Re-enable body scroll
            cleanupDrawer();
        };
    }, []);


    useEffect(() => {
        if (cardRef.current && cardLoaded && data) {
            cardRef.current.style.bottom = drawerStopPosition; // Reset position
            shadowRef.current.style.opacity = 1;
            cardRef.current.classList.add('loaded');
            shadowRef.current.classList.add('loaded');
            if (onLoad) onLoad();
        }
    }, [cardLoaded, data, cardRef.current]);


    const renderedContent = (
        <div className={`card-modal ${className || ''}`}>
            <div className="card-content" ref={cardRef}>
                <button className='close-handle' />
                {disclosureItems &&
                    <DisclosureComponent
                        isOpen={disclosureOpen}
                        toggleDisclosure={toggleDisclosure}
                        disclosureItems={disclosureItems}
                    />
                }
                <button className="close-btn" onClick={closeCard}><FontAwesomeIcon icon={faXmark} /></button>
                {headerContent &&
                    <div className="header">
                        {headerContent}
                    </div>}
                {horizontalContent &&
                    <div className='h-scroller' ref={hScrollingRef}>
                        {horizontalContent}
                    </div>
                }
                {verticalContent &&
                    <div className='v-scroller' ref={vScrollingRef}>
                        {verticalContent}
                    </div>
                }
            </div>
            <div className='shadow' ref={shadowRef}></div>
        </div>
    );

    return renderedContent;
};

export default CardComponent;
