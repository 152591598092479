import React, { useState, useEffect } from 'react';

const FilterCreate = () => {
  const [advance, setAdvance] = useState(true);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Enter Filter Settings</h2></div>
      <div className="onboarding-main">
        <h3>Add this to the 'From' field <br/>in Gmail on desktop</h3>
        <div className='forwarding-address'>rbusd.org OR signupgenius.com OR rbef.org</div>
        <div className='note'>This rule forwards mail from the addresses you chose previously</div>
        <br/>
        <p>Then click '<b>Create filter</b>'</p>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/filter/address' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default FilterCreate;
