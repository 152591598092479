import React, { useState, useEffect } from 'react';

const FilterAddress = () => {
  const [advance, setAdvance] = useState(true);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };


  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Complete Your Filter</h2></div>
      <div className="onboarding-main">
        <h3>Finish these steps in Gmail<br />on your desktop</h3>
        <ol>
          <li>Click '<b>Forward it to:</b>'</li>
          <li>Select '<b>inbox@simpleschedule.me</b>'</li>
          <li>Click '<b>Create filter</b>'</li>
          <li>Follow '<b>Verify it's you</b>'</li>
        </ol>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/filter/success' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default FilterAddress;
