import React, { useState, useEffect } from 'react';

const ForwardFilter = () => {
  const [advance, setAdvance] = useState(false);
  const [profile, setProfile] = useState(null);
  const [lastLocation, setLastLocation] = useState(null);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };


  return (
    <div className='onboarding'>
      <div className="onboarding-title">
        <h2>Control Your Email <br/>Forwarding</h2>
      </div>
      <div className="onboarding-main">
        <h3>Limit which emails are sent <br/>to Simple Schedule</h3>
        <h3>Filters set the rules for what emails<br/> are forwarded to your account</h3>
      </div>
      <div className="onboarding-continue">
        <button className='continue' onClick={() => { window.location.href = '/filter/settings' }}>
          Setup Filters
        </button>
        <button onClick={() => window.location.href = '/focus'} className="cancel">
          Do Later
        </button>
      </div>
    </div>
  );
};

export default ForwardFilter;
