import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

const ForwardSettings = () => {
  const [advance, setAdvance] = useState(true);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Setup Email Forwarding</h2></div>
      <div className="onboarding-main">
        <h3>Follow these steps in <br/>Gmail on your desktop</h3>
        <ol>
          <li>Click the <FontAwesomeIcon className="icon" icon={faGear} /> icon in the top right of Gmail</li>
          <li>Select '<b>See all settings</b>'</li>
          <li>Click '<b>Forwarding and POP/IMAP</b>'</li>
          <li>Click '<b>Add a forwarding address</b>'</li>
        </ol>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/forward/inbox' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default ForwardSettings;
