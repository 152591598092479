import { Outlet } from 'react-router-dom';
import { ProfileProvider } from './ProfileContext';

const ProfileContextLayout = () => {

  return (
    <ProfileProvider>
      <Outlet />
    </ProfileProvider>
  );
};

export default ProfileContextLayout;