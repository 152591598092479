import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FeedContext } from '../contexts/FeedContext';
import { formatMonth, isThisMonth, isToday, formatTimeRange } from '../utils/FormatDates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendarCheck, faCalendarPlus, faCheckCircle, faEnvelopeOpen, faFlag } from '@fortawesome/free-regular-svg-icons'
import { faBoltLightning, faCalendar, faCalendarWeek, faCaretDown, faCheck, faChevronDown, faCircleCheck, faCircleUser, faPlugCircleBolt, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons'
import '../styles/Navigation.css'

const FocusNavigation = ({
    navRef,
    feedContainerRef,
    currentDayRef,
    openCard,
    setupFeedIntersectionObserver,
    navExpandedRef,
    swipingActiveRef,
    scrollToToday,
    scrollToDay,
    resetFeedScroll,
    disableFeedScroll,
    handleNavigationIntro }) => {

    const { startTimestamp, months } = useContext(FeedContext);

    //Basic Navigation
    const monthsRef = useRef(null); //Displays the current month

    //Expanded Navigation
    const calendarContainerRef = useRef(null); //Contains the drop down swipable calendar navigation
    const calendarTrayRef = useRef(null); //Sliding tray holds all the monthly calendars
    const startXRef = useRef(0); // Starting X position of the touch
    const trayStartXRef = useRef(0); // Initial translateX value of the tray
    const calendarContainerWidth = useRef(0); // Width of the calendar container
    const currentIndexRef = useRef(0); // Keeps track of the current visible month
    const calendarMap = useRef();

    /* Init navigation on months data */
    useEffect(() => {
        if (!months) return; // guard on dependency

        positionMonths();

        if (navRef.current) {
            navRef.current.addEventListener("animationend", handleAnimationEnd);
        }

        return () => {
            if (navRef.current) {
                navRef.current.removeEventListener("animationend", handleAnimationEnd);
            }
        };
    }, [months]);

    /* Months move as user scrolls up and down */
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [feedContainerRef.current]);

    /* Handler for navigation intro */
    const handleAnimationEnd = (event) => {
        if (event.animationName === "expandToPill") { // Check if all animations have completed
            if (handleNavigationIntro) {
                handleNavigationIntro();
            }
        }
    };

    const handleScroll = () => {
        // currentDayRef
        positionMonths();

        if (!swipingActiveRef.current && navExpandedRef.current && currentDayRef.current) {
            slideToCalendar(currentDayRef.current);
        }

        if (navExpandedRef.current && currentDayRef.current) {
            highlightCalendarDay(currentDayRef.current);
        }
    };

    /* Positions the relevant month in the navigation */
    function positionMonths() {

        const currentMonth = currentDayRef.current?.slice(0, 7);
        const nav = navRef.current;
        const months = monthsRef.current;
        const feedContainer = feedContainerRef.current;

        if (!currentMonth || !nav || !months || !feedContainer) return;

        const currentMonthElement = monthsRef.current.querySelector(`[data-month="${currentMonth}"]`);

        if (currentMonthElement && monthsRef.current) {
            monthsRef.current.scrollTo({
                top: currentMonthElement.offsetTop,
                behavior: "smooth"
            });
        }
    }

    const handleTouchStart = (e) => {
        e.preventDefault();
        // disable feed scrolling
        disableFeedScroll();

        // Sets the width setting for the nav slider
        setCalendarNav();

        // Capture the starting position of the swipe
        startXRef.current = e.touches[0].clientX;

        // Get the starting translate X from the calendarTray
        const matrix = getComputedStyle(calendarTrayRef.current).transform;
        trayStartXRef.current = matrix !== 'none' ? parseFloat(matrix.split(',')[4]) : 0;

    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        // Calculate the horizontal swipe distance
        const deltaX = e.touches[0].clientX - startXRef.current;

        // Apply the translateX value based on the swipe movement
        calendarTrayRef.current.style.transform = `translateX(${trayStartXRef.current + deltaX}px)`;

    };

    const handleTouchEnd = (e) => {
        e.preventDefault();
        resetFeedScroll();

        // Calculate the current position of the tray
        const matrix = getComputedStyle(calendarTrayRef.current).transform;
        const currentTranslateX = matrix !== 'none' ? parseFloat(matrix.split(',')[4]) : 0;

        // Calculate the swipe distance as a percentage of the container width
        const swipeDistance = currentTranslateX - trayStartXRef.current;
        const swipePercentage = Math.abs(swipeDistance / calendarContainerWidth.current);

        // Check if the swipe is beyond the 20% threshold
        let swipe = false;
        if (swipePercentage > 0.30) {
            if (swipeDistance < 0 && currentIndexRef.current < calendarTrayRef.current.children.length - 1) {
                // Check if document is at max scroll.
                //console.log(window.innerHeight, window.scrollY, document.body.offsetHeight)
                // Replace the existing scroll check with:
                if (feedContainerRef.current) {
                    const feedContainer = feedContainerRef.current;
                    const scrollPosition = window.scrollY;

                    const maxScroll = feedContainer.scrollHeight - window.innerHeight + 100; //
                    // Check if we've scrolled to the bottom of the feed container
                    if (scrollPosition <= maxScroll) { // -1 to account for rounding
                        currentIndexRef.current++; // Swipe left
                        swipe = true;
                    }

                }
            } else if (swipeDistance > 0 && currentIndexRef.current > 0) {
                currentIndexRef.current--; // Swipe right
                swipe = true;
            }
        }

        if (swipe) {
            const calendarDiv = calendarTrayRef.current.children[currentIndexRef.current];
            const dataValue = calendarDiv.getAttribute('data-month');
            const calData = calendarMap.current[dataValue];
            const firstDayWithEvent = calData.days.find((day) => day.hasEvent);

            // Swiping is active
            swipingActiveRef.current = true;

            if (firstDayWithEvent) scrollToDay(firstDayWithEvent.date);

        } else {
            // Check if e.target has 'data-day' attribute, or direct parent has attribute then click
            const target = e.target;
            const parent = target.parentElement;
            if (target.hasAttribute('data-day')) {
                target.click();
            } else if (parent && parent.hasAttribute('data-day')) {
                parent.click();
            }

        }

        // Animate to the calculated month (or snap back)
        moveCalendarSlider(currentIndexRef.current);
    };

    const toggleMonthlyCalender = () => {
        if (navRef.current.classList.contains('expand')) {
            collapseNav();
        } else {
            expandNav();
        }
    }

    const navTransitionHandlerRef = useRef();
    const touchStartHandlerRef = useRef();
    const touchMoveHandlerRef = useRef();
    const touchEndHandlerRef = useRef();

    useEffect(() => {
        return () => {
            if (navRef.current && navTransitionHandlerRef.current) {
                navRef.current.removeEventListener('transitionend', navTransitionHandlerRef.current);
            }

            if (calendarContainerRef.current) {
                calendarContainerRef.current.removeEventListener('touchstart', touchStartHandlerRef.current);
                calendarContainerRef.current.removeEventListener('touchmove', touchMoveHandlerRef.current);
                calendarContainerRef.current.removeEventListener('touchend', touchEndHandlerRef.current);
            }
        };
    }, []);

    const expandNav = () => {
        console.log('expandNav');

        navRef.current.classList.add('expand');
        navTransitionHandlerRef.current = () => {
            navExpandedRef.current = true;
            setupFeedIntersectionObserver();
            setCalendarNav();
            slideToCalendar(currentDayRef.current, true);

            const navRect = navRef.current.getBoundingClientRect();
            const monthsRect = monthsRef.current.getBoundingClientRect();
            const fsOffset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--fs-top-offset')) || 0;
            const feedOffset = navRect.height - navRect.top - monthsRect.height + fsOffset;

            feedContainerRef.current.style.transition = 'transform 0.2s ease-out';
            feedContainerRef.current.style.transform = `translateY(${feedOffset}px)`;
        }

        navRef.current.addEventListener('transitionend', navTransitionHandlerRef.current, { once: true });


        if (calendarContainerRef.current) {

            touchStartHandlerRef.current = handleTouchStart;
            touchMoveHandlerRef.current = handleTouchMove;
            touchEndHandlerRef.current = handleTouchEnd;
            calendarContainerRef.current.addEventListener('touchstart', touchStartHandlerRef.current, { passive: false });
            calendarContainerRef.current.addEventListener('touchmove', touchMoveHandlerRef.current, { passive: false });
            calendarContainerRef.current.addEventListener('touchend', touchEndHandlerRef.current, { passive: false });
        }
    }

    const collapseNav = () => {
        navRef.current.classList.remove('expand');

        if (calendarContainerRef.current) {
            calendarContainerRef.current.removeEventListener('touchstart', touchStartHandlerRef.current, { passive: false });
            calendarContainerRef.current.removeEventListener('touchmove', touchMoveHandlerRef.current, { passive: false });
            calendarContainerRef.current.removeEventListener('touchend', touchEndHandlerRef.current, { passive: false });
        }
        // Move the following lines to happen after the transform
        navRef.current.addEventListener(
            'transitionend',
            () => {
                navExpandedRef.current = false;
                setupFeedIntersectionObserver();
            },
            { once: true }
        );

        feedContainerRef.current.style.transition = 'transform 0.2s ease-out';
        feedContainerRef.current.style.transform = `translateY(0px)`;
    }

    const highlightCalendarDay = () => {
        if (!currentDayRef.current) {
            return;
        }

        // Remove 'active' class from all buttons
        const buttons = calendarTrayRef.current.querySelectorAll('button.active');
        buttons.forEach(button => button.classList.remove('active'));

        // Add 'active' class to the button with matching data-day attribute
        const activeButton = calendarTrayRef.current.querySelector(`button[data-day="${currentDayRef.current}"]`);
        if (activeButton) {
            activeButton.classList.add('active');
        }
    }

    const slideToCalendar = (targetDate, noAnimation) => {
        if (!calendarTrayRef.current || !calendarMap.current || Object.keys(calendarMap.current).length === 0) return;

        const { targetMonthKey, firstDayWithEvent } = findMonthAndFirstDay(targetDate) || {};
        if (!targetMonthKey) return;

        // Get the target month index
        const targetIndex = Array.from(calendarTrayRef.current.children).findIndex(
            (child) => child.getAttribute('data-month') === targetMonthKey
        );

        if (targetIndex === -1) {
            console.warn("Target month not found in calendarTrayRef children.");
            return;
        }

        if (targetIndex !== currentIndexRef.current) { // Only apply animation if there's a change
            // Update the current index ref
            currentIndexRef.current = targetIndex;

            // Apply the snap animation
            moveCalendarSlider(targetIndex, noAnimation);
        }
    };

    // Helper function: Apply snap animation
    const moveCalendarSlider = (targetIndex, noAnimation) => {
        const snapPosition = -targetIndex * calendarContainerWidth.current;

        // Set the new position
        calendarTrayRef.current.style.transform = `translateX(${snapPosition}px)`;

        const isExpanded = navRef.current.classList.contains('expand');

        // Apply the slide animation
        if (isExpanded && !noAnimation) {
            calendarTrayRef.current.style.transition = 'transform 0.2s ease-out';

            // Remove the transition after snapping
            calendarTrayRef.current.addEventListener(
                'transitionend',
                () => {
                    calendarTrayRef.current.style.transition = '';
                },
                { once: true }
            );
        }

        highlightCalendarDay();
    };

    // Helper function: Find the month and first day with an event
    const findMonthAndFirstDay = (targetDate) => {
        const targetMonthKey = Object.keys(calendarMap.current).find((monthKey) => {
            const calData = calendarMap.current[monthKey];
            return calData.days.some((day) => day.date === targetDate); // Match the date
        });

        if (!targetMonthKey) {
            console.warn("Date does not belong to any month in calendarMap.");
            return null;
        }

        const calData = calendarMap.current[targetMonthKey];
        const firstDayWithEvent = calData.days.find((day) => day.hasEvent);

        return { targetMonthKey: targetMonthKey, firstDayWithEvent: firstDayWithEvent };
    };

    const setCalendarNav = () => {
        // Set the width of the calendarContainer
        calendarContainerWidth.current = calendarContainerRef.current.offsetWidth;
    }

    const buildCalendar = () => {

        calendarMap.current = {};

        [...months.entries()].forEach(([monthKey, monthDays]) => {
            // Parse the month key (YYYY-MM format)
            const monthDate = moment(monthKey, 'YYYY-MM');
            const firstDayOfMonth = monthDate.startOf('month').day(); // 0-6 (Sunday-Saturday)
            const daysInMonth = monthDate.daysInMonth();

            // Create month entry if it doesn't exist
            if (!calendarMap.current[monthKey]) {
                calendarMap.current[monthKey] = {
                    days: []
                };

                // Add padding days from previous month
                for (let i = 0; i < firstDayOfMonth; i++) {
                    const paddingDate = monthDate.clone()
                        .startOf('month')
                        .subtract(firstDayOfMonth - i, 'days');

                    calendarMap.current[monthKey].days.push({
                        date: paddingDate.format('YYYY-MM-DD'),
                        hasEvent: false,
                        otherMonth: true
                    });
                }

                // Add current month days
                for (let day = 1; day <= daysInMonth; day++) {
                    const currentDate = monthDate.clone().date(day);
                    calendarMap.current[monthKey].days.push({
                        date: currentDate.format('YYYY-MM-DD'),
                        hasEvent: false,
                        otherMonth: false
                    });
                }
            }

            // Mark days that have events
            monthDays.forEach(({ day: dayDate }) => {  // Destructure the day property
                const dayIndex = calendarMap.current[monthKey].days.findIndex(
                    d => moment(dayDate).isSame(moment(d.date), 'day')
                );
                if (dayIndex !== -1) {
                    calendarMap.current[monthKey].days[dayIndex].hasEvent = true;
                }
            });
        });
    };

    const renderCalendars = useCallback(() => {

        buildCalendar();

        return Object.keys(calendarMap.current).map((monthKey) => {
            const calendar = calendarMap.current[monthKey]; // Get the month object

            return (
                <div className='calendar-details' key={monthKey} data-month={monthKey}>
                    <div className='heading'>
                        <span className='label'>SUN</span>
                        <span className='label'>MON</span>
                        <span className='label'>TUE</span>
                        <span className='label'>WED</span>
                        <span className='label'>THU</span>
                        <span className='label'>FRI</span>
                        <span className='label'>SAT</span>
                    </div>
                    <div className="calendar">
                        {calendar.days.map((day) => (
                            <button
                                key={day.date}
                                className={`day ${day.hasEvent ? 'events' : 'noclick'} ${day.otherMonth ? 'prev-month' : ''}`}
                                data-day={day.date}
                                {...(day.hasEvent && { onClick: (e) => { e.preventDefault(); e.stopPropagation(); scrollToDay(day.date) } })}
                            >
                                <div className={`date ${isToday(day.date)}`}>
                                    {moment(day.date).format('D')}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            );
        });
    }, [months]);

    const renderMonths = useCallback(() => {
        return Array.from(months.entries()).map(([monthKey, monthData]) => (
            <div data-month={monthKey} key={monthKey} className={isThisMonth(monthKey, startTimestamp) ? 'month-title today' : 'month-title'}>
                {formatMonth(monthKey, startTimestamp)} <FontAwesomeIcon className="icon" icon={faCaretDown} />
            </div>
        ));
    }, [months]);


    return (
        <div className='nav' ref={navRef}>
            <div className='top'>
                <div className='months-container' ref={monthsRef} onClick={toggleMonthlyCalender}>
                    {(months && months.size > 0) && renderMonths()}
                </div>
                <button className='profile' onClick={(e) => { window.location.href = '/profile' }}>
                    <FontAwesomeIcon className="icon" icon={faCircleUser} />
                </button>
                <button className='latest' onClick={(e) => { openCard(); }}>
                    <FontAwesomeIcon className="icon" icon={faBell} />
                </button>
            </div>
            <div className='divider'></div>
            <div className='bottom'>
                <div className='calendar-container'
                    ref={calendarContainerRef}>
                    <div className='calendar-tray'
                        ref={calendarTrayRef}>
                        {(months && months.size > 0) && renderCalendars()}
                    </div>
                </div>
                <button className="close-btn" onClick={collapseNav} ><FontAwesomeIcon className="icon" icon={faXmark} /></button>
            </div>
        </div>
    );
}


export default FocusNavigation;