import React, { useState, useEffect } from 'react';

const ForwardConfirmation = () => {
  const [advance, setAdvance] = useState(true);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Send Forwarding <br/>Confirmation</h2></div>
      <div className="onboarding-main">
      <h3>Finish these steps in <br/>Gmail on your desktop</h3>
        <p>'<b>Verify it's you</b>' if required</p>
        <p>Click '<b>proceed</b>'</p>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/forward/allow' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default ForwardConfirmation;
