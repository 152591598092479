import React, { useState, useEffect } from "react";
import Handlebars from "handlebars";

const EmailPreview = () => {
  const [templateSource, setTemplateSource] = useState('');

  useEffect(() => {
    fetch('/templates/weeklySchedule.html')
      .then(response => response.text())
      .then(text => {
        setTemplateSource(text);
      })
      .catch(error => console.error('Error loading template:', error));
  }, []);

  // Don't render until template is loaded
  if (!templateSource) return null;

  const template = Handlebars.compile(templateSource);

  const scheduleData = {
    "displayName": "Johannes",
    "days": [
      { 
        "date": "Mar 31", 
        "short": "Mo", 
        "events": [
          { "time": "9am", "title": "Meeting with Bob", "icon": "👥" },
          { "time": "5:30pm", "title": "Gym", "icon": "💪" }
        ]
      },
      { 
        "date": "Apr 1", 
        "short": "Tu", 
        "events": [
          { "time": "3pm", "title": "Project deadline", "icon": "📅" }
        ]
      },
      { 
        "date": "Apr 2", 
        "short": "We", 
        "events": [
          { "time": "10:30am", "title": "Doctor appointment", "icon": "🏥" }
        ]
      },
      { 
        "date": "Apr 3", 
        "short": "Th", 
        "events": [
          { "time": "12:59pm", "title": "Team lunch", "icon": "🍽️" }
        ]
      },
      { 
        "date": "Apr 4", 
        "short": "Fr", 
        "events": [
          { "time": "2pm", "title": "Presentation", "icon": "📊" },
          { "time": "7pm", "title": "Dinner with friends", "icon": "🍷" }
        ]
      },
      { 
        "date": "Apr 5", 
        "short": "Sa", 
        "events": [
          { "time": "Free", "title": "No Events" }
        ]
      },
      { 
        "date": "Apr 6", 
        "short": "Su", 
        "events": [
          { "time": "All Day", "title": "Relax", "icon": "🌴" }
        ]
      }
    ]
  };

  const compiledHtml = template(scheduleData);

  return (
    <div dangerouslySetInnerHTML={{ __html: compiledHtml }} />
  );
};

export default EmailPreview;
