import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faGear } from '@fortawesome/free-solid-svg-icons';

const ForwardAllow = () => {
  const [advance, setAdvance] = useState(true);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Allow Forwarding</h2></div>
      <div className="onboarding-main">
        <h3>Check your Gmail inbox for a FWD<br/> from Simple Schedule</h3>
        <h3>"FWD: (Gmail Forwarding Confirmation..."</h3>
        <h3>The confirmation link to verify your permission is in this forwarded email.</h3>
        <p>Simple Schedule sends this to your original <br/>address for your convenience.</p>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/forward/success' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default ForwardAllow;
