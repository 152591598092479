import { BrowserRouter as Layout, Route, Routes } from 'react-router-dom';
import { GlobalPromptsProvider } from './contexts/GlobalPromptsContext';
import { useGlobalPrompts } from './contexts/GlobalPromptsContext';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { onForegroundMessage } from './services/firebaseService';

import Home from './pages/Home';
import Join from './pages/Join';
import JoinEmail from './pages/JoinEmail';
import JoinCalendar from './pages/JoinCalendar';
import JoinWatchlist from './pages/JoinWatchlist';
import JoinSuccess from './pages/JoinSuccess';
import Focus from './pages/Focus';
import Profile from './pages/Profile';
import Install from './pages/Install';

import ProfileContextLayout from './contexts/ProfileContextLayout';
import FeedContextLayout from './contexts/FeedContextLayout';

import EventComponent from './components/EventComponent';
import MessageComponent from './components/MessageComponent';

import ForwardFilter from './pages/ForwardFilter';
import ForwardSettings from './pages/ForwardSettings';
import ForwardAddresses from './pages/ForwardAddresses';
import ForwardConfirmation from './pages/ForwardConfirmation';
import ForwardAllow from './pages/ForwardAllow';
import ForwardSuccess from './pages/ForwardSuccess';
import ForwardInbox from './pages/ForwardInbox';
import FilterSettings from './pages/FilterSettings';
import FilterCreate from './pages/FilterCreate';
import FilterAddress from './pages/FilterAddress';
import FilterSuccess from './pages/FilterSuccess';
import ForwardAddress from './pages/ForwardAddress';
import Notifications from './pages/Notifications';
import EmailPreview from './pages/EmailPreview';

const AppRoutes = () => {
  const { showToast } = useGlobalPrompts();

  useEffect(() => {
    onForegroundMessage((data) => {
      showToast({
        icon: <FontAwesomeIcon className='icon ring' icon={faBell} />,
        title: data.notification.title,
        description: data.notification.body
      });
    });
  }, [showToast]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/join" element={<Join />} />
        <Route path='/email/preview' element={<EmailPreview />} />
        <Route element={<ProfileContextLayout />} >
          <Route path="/join/email" element={<JoinEmail />} />
          <Route path="/join/watchlist" element={<JoinWatchlist />} />
          <Route path="/join/calendar" element={<JoinCalendar />} />
          <Route path="/forward/address" element={<ForwardAddress />} />
          <Route path="/forward/addresses" element={<ForwardAddresses />} />
          <Route path="/forward/settings" element={<ForwardSettings />} />
          <Route path="/forward/inbox" element={<ForwardInbox />} />
          <Route path="/forward/confirmation" element={<ForwardConfirmation />} />
          <Route path="/forward/allow" element={<ForwardAllow />} />
          <Route path="/forward/success" element={<ForwardSuccess />} />
          <Route path="/forward/filter" element={<ForwardFilter />} />
          <Route path='/filter/settings' element={<FilterSettings />} />
          <Route path='/filter/create' element={<FilterCreate />} />
          <Route path='/filter/address' element={<FilterAddress />} />
          <Route path='/filter/success' element={<FilterSuccess />} />
          <Route path="/install" element={<Install />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route element={<FeedContextLayout />} >
            <Route path="/join/success" element={<JoinSuccess />} />
            <Route path="/tutorial" element={<Focus tutorial={true} />} >
              <Route path="event/:id" element={<EventComponent />} />
              <Route path="message/:id" element={<MessageComponent />} />
            </Route>
            <Route path="/focus" element={<Focus tutorial={false} />} >
              <Route path="event/:id" element={<EventComponent />} />
              <Route path="message/:id" element={<MessageComponent />} />
            </Route>
          </Route>
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </Layout>
  );
};

const App = () => {
  return (
    <GlobalPromptsProvider>
      <AppRoutes />
    </GlobalPromptsProvider>
  );
};

export default App;