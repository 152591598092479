import React, { createContext, useState, useContext } from 'react';
import PromptComponent from '../components/PromptComponent';
import ToastComponent from '../components/ToastComponent';

// Create the context
const GlobalPromptsContext = createContext();

// Provide a hook for consuming the context
export const useGlobalPrompts = () => useContext(GlobalPromptsContext);

// Create the provider
export const GlobalPromptsProvider = ({ children }) => {
  const [promptProps, setPromptProps] = useState(null);
  const [toastProps, setToastProps] = useState(null);

  // Show the prompt
  const showPrompt = ({ icon, title, description, onConfirm, confirmMessage, onCancel, cancelMessage }) => {
    setPromptProps({ icon, title, description, onConfirm, confirmMessage, onCancel, cancelMessage });
  };

  // Hide the prompt
  const hidePrompt = () => {
    setPromptProps(null);
  };

  // Show the toast
  const showToast = ({ icon, title, description, displayTime = 5000 }) => {
    setToastProps({ icon, title, description, displayTime });

    // Automatically dismiss the toast after displayTime
    /*
    setTimeout(() => {
      setToastProps(null);
    }, displayTime);
    */
  };

  // Hide the toast
  const hideToast = () => {
    setToastProps(null);
  };

  return (
    <GlobalPromptsContext.Provider value={{ showPrompt, hidePrompt, showToast, hideToast }}>
      {children}

      {/* Render the PromptComponent */}
      {promptProps && (
        <PromptComponent
          {...promptProps}
          onClose={hidePrompt}
          confirmAction={() => {
            if (promptProps.onConfirm) promptProps.onConfirm();
            hidePrompt();
          }}
        />
      )}

      {/* Render the ToastComponent */}
      {toastProps && (
        <ToastComponent
          {...toastProps}
          onClose={hideToast}
        />
      )}
    </GlobalPromptsContext.Provider>
  );
};
