import React, { useState, useEffect } from 'react';
import WatchlistComponent from '../components/WatchlistComponent';

const ForwardAddresses = () => {
  const [advance, setAdvance] = useState(false);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Setup your addresses</h2></div>
      <div className="onboarding-main">
        <h3>Select or enter the addresses <br />you want used for your schedule</h3>
        <WatchlistComponent onAdvanceChange={handleAdvanceChange} />
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/forward/settings' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default ForwardAddresses;
