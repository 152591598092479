import React, { useState, useEffect } from 'react';
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';
import ForwardingAddressComponent from '../components/ForwardingAddressComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

const ForwardInbox = () => {
    const { showToast } = useGlobalPrompts();
  const [advance, setAdvance] = useState(true);
  const [forwardingAddress, setForwardingAddress] = useState(null);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  const handleAddressCopy = () => {
    navigator.clipboard.writeText(forwardingAddress).then(() => {
      showToast({
        icon: <FontAwesomeIcon className='icon' icon={faCopy} />,
        title: 'Copied to Clipboard',
        description: forwardingAddress
      });
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  const fetchProfileData = async () => {

    try {
      const [profileResponse] = await Promise.all([
        fetch('/api/user/profile', { credentials: 'include' }),
      ]);

      if (profileResponse.ok) {
        const profileData = await profileResponse.json();
        const user = profileData.user;

        if (user && user.forwardingAddress && user.forwardingAddress.id) {
          if (!forwardingAddress) setForwardingAddress(user.forwardingAddress.address);

          //setForwardingAddressActive(user.forwardingAddress.active);
        }

      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [])

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Enter Forwarding Address</h2></div>
      <div className="onboarding-main forward inbox">
        <h3>Add this forwarding address <br />to Gmail on desktop</h3>
        <ForwardingAddressComponent
          forwardingAddress={forwardingAddress}
          handleAddressCopy={handleAddressCopy}
        />
        <div className='note'>Type this exact address into Gmail</div>
        <br />
        <p>Then click '<b>next</b>'</p>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/forward/confirmation' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default ForwardInbox;
