import React, { useEffect, useRef } from 'react';
import '../styles/PromptComponent.css';

const PromptComponent = ({
    icon,
    title,
    description,
    onConfirm,
    confirmMessage = 'Okay',
    onCancel,
    cancelMessage,
    onClose,
}) => {
    const overlayRef = useRef(null);

    const handleClickOutside = (e) => {
        if (overlayRef.current && e.target === overlayRef.current) {
            onClose();
        }
    };

    const handleConfirm = () => {
        if (onConfirm) onConfirm();
        onClose();
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
        onClose();
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onClose]);

    return (
        <div className="prompt-dialog" >
            <div className='overlay'ref={overlayRef} onClick={handleClickOutside}></div>
            <div className="dialog-container">
                {icon && <div className="icon-container">{icon}</div>}
                <h2 id="dialog-title" className="dialog-title">{title}</h2>
                <div className="dialog-description">{description}</div>
                <div className="dialog-buttons">
                    {cancelMessage && <div className="dialog-cancel" onClick={handleCancel}>
                        {cancelMessage}
                    </div>}
                    <button className="dialog-confirm" onClick={handleConfirm}>
                        {confirmMessage}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PromptComponent;
