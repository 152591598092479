import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { config } from "../firebase-config" // json object with config only

const VAPID_KEY = "BAR7kJ8aKZyzR6I4GEkfcl0XDtyZkcB2vREb6YnVIT-30FluINuzHcWJgQe2zsQgoYWVVK9VJNhfVzD6ek3TyyI"; // Found in Firebase console under Cloud Messaging

let messageHandler = null;

// Initialize Firebase
const firebaseApp = initializeApp(config);
const messaging = getMessaging(firebaseApp);

/**
 * Initialize Firebase messaging and set up persistent listeners
 */
export const initializeMessaging = async () => {
    try {
        const permission = await checkNotificationPermission();
        console.log('initializeMessaging: ' + permission)
        if (!permission) return;

        const token = await getToken(messaging, { vapidKey: VAPID_KEY });
        console.log(token);

        // Set up persistent message handler
        if (!messageHandler) {
            messageHandler = (payload) => {
                console.log("Message received:", payload);
                // Default notification if no custom handler
                if (Notification.permission === "granted") {
                    new Notification(payload.notification.title, {
                        body: payload.notification.body
                    });
                }
            };
            onMessage(messaging, messageHandler);
        }
    } catch (error) {
        console.error("Error initializing messaging:", error);
    }
};

export const getServiceToken = async () => {
    const token = await getToken(messaging, { vapidKey: VAPID_KEY });
    return token;
}

/**
 * Request notification permission and retrieve FCM token.
 * @returns {Promise<string|null>} The FCM token or null if permission denied.
 */
export const requestNotificationPermission = async () => {
    console.log('requestNotificationPermission')
    try {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
            console.warn("Push notifications permission denied.");
            return null;
        }

        const token = await getToken(messaging, {
            vapidKey: VAPID_KEY
        });
        
        console.log("FCM Token:", token);

        // Send initial token to backend
        await fetch("/api/push/subscribe", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token }),
        });

        await initializeMessaging();

        return token;
    } catch (error) {
        console.error("Error requesting notification permission:", error);
        return null;
    }
};

/**
 * Check if push notifications are currently enabled.
 * @returns {Promise<boolean>} True if notifications are enabled, false otherwise.
 */
export const checkNotificationPermission = async () => {
    console.log('checkNotificationPermission')
    try {
        // Check if browser supports notifications
        if (!('Notification' in window)) {
            console.warn('This browser does not support notifications');
            return false;
        }

        // Check existing permission
        const permission = Notification.permission;
        
        if (permission === 'granted') {
            // Check if we have a valid service worker registration
            const registration = await navigator.serviceWorker.ready;
            if (registration) {
                // Check if we have a valid FCM token
                const token = await getToken(messaging, { vapidKey: VAPID_KEY });
                return !!token;
            }
        }
        
        return false;
    } catch (error) {
        console.error('Error checking notification permission:', error);
        return false;
    }
};

/**
 * Set custom message handler
 */
export const onForegroundMessage = (callback) => {
    messageHandler = callback;
    onMessage(messaging, messageHandler);
};

/**
 * Registers service worker if not already registered.
 * @returns {Promise<ServiceWorkerRegistration|null>} The registration or null if failed
 */
export const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            // Check for existing registration
            const existingRegistration = await navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope');
            if (existingRegistration) {
                console.log('Existing Service Worker found:', existingRegistration);
                return existingRegistration;
            }

            // Register new service worker if none exists
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
            console.log('New Service Worker registered:', registration);
            return registration;
        } catch (error) {
            console.error('Service Worker registration failed:', error);
        }
    }
    return null;
};

