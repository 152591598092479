import React from 'react';

const Join = () => {
  return (
    <div className='join onboarding'>
      <div className="onboarding-title"></div>
      <div className="onboarding-main">
        <h3>Effortlessly know what's <br />next for your kids</h3>
        <h3>Stress-free school and sports <br />scheduling for you</h3>
      </div>
      <div className="onboarding-continue">
        {/*<button className='google-button continue' onClick={() => { window.location.href = '/api/auth/google/gmail' }}>Sign In</button> */}
        <button className='google-button continue' onClick={() => { window.location.href = '/api/auth/google' }}>Sign Up</button>
        <div className='note legal'>By continuing, you agree to our <a href="/privacy-policy.html">Privacy Policy</a> and <a href="/terms-of-service.html">Terms of Use</a>.</div>
      </div>
    </div>
  );
};

export default Join;