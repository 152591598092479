import React, { useState, useEffect } from 'react';
import WatchlistComponent from '../components/WatchlistComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';

const JoinWatchlist = () => {
  const { showPrompt } = useGlobalPrompts();
  const [advance, setAdvance] = useState(false);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  const handleHelp = () => {
    showPrompt({
      icon: <FontAwesomeIcon className='prompt-icon' icon={faQuestionCircle} />,
      title: 'Your Addresses',
      description:
        <>
          <div className='description'>Simple Schedule only scans emails from <br/>addresses you choose.</div>
          <div className='description'>Add teachers' or coaches' email addresses directly, <br/>or enter a domain (e.g., lausd.org) to include all emails from that source.</div>
          <div className='description'>Your schedule is created from the emails in this list.</div>
        </>,
      confirmMessage: 'Okay'
    });
  }

  return (
    <div className='addresses onboarding'>
      <div className="onboarding-title"><h2>Setup your addresses</h2></div>
      <div className="onboarding-main">
        <h3>Choose or enter the addresses <br />used for your schedule</h3>
        <h3 onClick={handleHelp}>The items in this list will be scanned for <br />school and sports events <FontAwesomeIcon className='icon' icon={faQuestionCircle} /></h3>
        <WatchlistComponent onAdvanceChange={handleAdvanceChange} />
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/join/success' }}>Continue</button>
      </div>
    </div>
  );
};

export default JoinWatchlist;
