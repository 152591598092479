import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useGlobalPrompts } from '../contexts/GlobalPromptsContext';

const JoinEmail = () => {
  const { showPrompt } = useGlobalPrompts();

  const handleHelp = () => {
    console.log('handle help')
    showPrompt({
      icon: <FontAwesomeIcon className='prompt-icon' icon={faQuestionCircle} />,
      title: 'This app works by scanning emails',
      description:
        <>
          <div className='description'>Every account gets a personal forwarding address. Manually forward emails, or set up automatic forwarding to activate Simple Schedule.</div>
          <div className='description'>Connecting Gmail is more convenient, but you may want more control by using a forwarding address.</div>
          <div className='description'>We keep your data secure and will never sell it.<br/>Your privacy is our priority.</div>
        </>,
      confirmMessage: 'Okay'
    });
  }

  return (
    <div className='email onboarding'>
      <div className="onboarding-title"><h2>Simple Schedule needs<br /> Email Access</h2></div>
      <div className="onboarding-main">
        <h3>Your schedule is created from<br /> the email messages sent to you</h3>
        <h3 onClick={handleHelp}>Connect Gmail to continue<br /> or forward emails <FontAwesomeIcon className='icon' icon={faQuestionCircle} /></h3>
      </div>
      <div className="onboarding-continue">
        <button className='gmail-button continue' onClick={() => { window.location.href = '/api/auth/google/gmail' }}>
          Allow Access <FontAwesomeIcon className='secure' icon={faLock} />
        </button>
        <button className="email-forwarding-button cancel" onClick={() => { window.location.href = '/forward/address' }}>
          Try another way
        </button>
      </div>
    </div>
  );
};

export default JoinEmail;
